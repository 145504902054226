import React, { useEffect, useState } from "react";
import styled from "styled-components";

// components
import Text from "components/atoms/Span";
import Img from "components/atoms/Image";
import { media } from "styles/media";
import { CDateTime } from "model/Date";

// image
import DownArrow from "assets/images/ic_down_white_arrow.svg";

type NoticeProps = {
  noticeDate?: string;
  message?: string;
};

const Notification = (props: any) => {
  const [isRotated, setIsRotated] = useState(false);

  const toggleRotation = () => {
    setIsRotated(!isRotated);
  };

  return props.notices?.length > 0 ? (
    <div style={{ position: "relative", zIndex: 99 }}>
      <Container>
        <SpanWrapper>
          <Text fontSize={13} lineHeight={15} color="#fff">
            {`${CDateTime.create(
              props.notices[0]?.noticeDate
            ).getAmPm()} ${CDateTime.create(
              props.notices[0].noticeDate
            ).getTime()}`}
          </Text>
          <Text fontSize={13} lineHeight={15} color="#fff">
            {props.notices[0].message}
          </Text>
        </SpanWrapper>
        <NotiWrapper>
          {isRotated &&
            props.notices.map((item: NoticeProps, idx: number) => {
              const timeFormat = CDateTime.create(item.noticeDate);
              return (
                <SpanWrapper
                  key={idx}
                  style={{
                    margin: "13.5px 12px 0px",
                  }}
                >
                  <Text fontSize={13} color="#fff">
                    {`${timeFormat.getAmPm()} ${timeFormat.getTime()}`}
                  </Text>
                  <Text fontSize={13} color="#fff">
                    {item.message}
                  </Text>
                </SpanWrapper>
              );
            })}
        </NotiWrapper>
      </Container>
      <ImgWrapper
        onClick={toggleRotation}
        style={{
          transform: isRotated ? "rotate(180deg)" : "",
          zIndex: 4,
        }}
      >
        <Img src={DownArrow} width={"100%"} />
      </ImgWrapper>
    </div>
  ) : (
    <NotiEmpty>
      <Text fontSize={13} lineHeight={15} color="#fff">
        5분 전에 나와서 기다리는 센스!
      </Text>
    </NotiEmpty>
  );
};

export default Notification;

const Container = styled.div`
  width: 100%;
  min-height: 47px;
  max-height: 142px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 16px 32px 16px 12px;
  background: #585858;

  ${media.tablet} {
    display: none;
  }
`;

const SpanWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 13px;
`;

const NotiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #585858;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;
  max-height: 142px;
  overflow: scroll;
  padding-bottom: 13.5px;
`;

const ImgWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  transition: transform 0.5s ease;

  ${media.tablet} {
    display: none;
  }
`;

const NotiEmpty = styled.div`
  background: #585858;
  padding: 16px;
  height: 48px;

  ${media.tablet} {
    display: none;
  }
`;
