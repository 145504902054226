import React from 'react'
import {Routes, Route} from 'react-router-dom'
import {ShuttleBus} from 'pages/ShuttleBus'
import BusRoute from 'pages/BusRoute'
import ChangeStation from 'pages/ChangeStation/ChangeStation'
import ChangeSchedule from 'pages/ChangeSchedule/ChangeSchedule'
import SearchStation from 'pages/ChangeSchedule/SearchStation'
import {IFrame} from 'pages/iframe'
import {IFrame2} from 'pages/iframe2'
import {ChangeScheduleIframe} from 'pages/ChangeScheduleIframe'

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<IFrame />} />
      <Route path="/course" element={<IFrame2 />} />
      <Route path="/changeschedule" element={<ChangeScheduleIframe />} />
      <Route path="/schedules" element={<ShuttleBus />} />
      <Route path="/info" element={<BusRoute />} />
      <Route path="/modify/station" element={<ChangeStation />} />
      <Route path="/modify/schedule" element={<ChangeSchedule />} />
      <Route path="/search/station" element={<SearchStation />} />
    </Routes>
  )
}

export default Router
