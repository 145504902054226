import React from "react";
import styled, { css } from "styled-components";

// componenets
import { media } from "styles/media";
import Text from "components/atoms/Span";
import Img from "components/atoms/Image";

// image
import DepartureStationIcon from "assets/images/ic_departure_station_icon.svg";
import ArrivalStationIcon from "assets/images/ic_arrival_station_icon.svg";
import BusStopArrow from "assets/images/ic_course_arrow_icon.svg";
import BusIconBlue from "assets/images/ic_bus_icon_blue.svg";
import Dot from "assets/images/ic_gray_dot.svg";
import BlueDot from "assets/images/ic_blue_dot.svg";
import BlueDotTop from "assets/images/ic_blue_dot_top.svg";
import BlueDotBottom from "assets/images/ic_blue_dot_bottom.svg";

type StationProps = {
  id: number;
  lat: number;
  lng: number;
  name: string;
};

type BusDetailProps = {
  arriveTargetTime: string;
  arriveTime: string;
  departureTime: string;
  position: string;
  station: StationProps;
};

type SelectionProps = {
  id: number;
  name: string;
};

type StationCourseProps = {
  order?: number;
  detail?: any;
  busDetail?: BusDetailProps;
  isLast: boolean;
  detailsCnt?: number;
  type?: string;
};

const Station: React.FC<StationCourseProps> = (props) => {
  const { order, detail, busDetail, isLast, type } = props;
  const stationId = Number(detail.station.id);
  const stationName = detail.station.name;

  const coursecnt = props.detailsCnt - 1;

  return (
    <Stationli
      key={detail.id}
      order={order}
      stationid={stationId}
      pickupstationid={detail?.station?.students[0]?.pickUpStationId}
    >
      <TimeBox arriveTime={detail.arriveTime}>
        <TimeGroup>
          <EstimatedArrivalTime>{detail.station.time}</EstimatedArrivalTime>
        </TimeGroup>
      </TimeBox>
      <StationBox
        order={order}
        coursecnt={Number(coursecnt)}
        isstudent={detail.station.students?.length}
        stationName={stationName}
      >
        <StationName>
          <Text
            fontSize={16}
            fontWeight={500}
            lineHeight={24}
            color={"#191919"}
          >
            {detail.station?.name}
          </Text>
        </StationName>
      </StationBox>
      {isLast && (
        <ImgWrapper>
          <Img src={Dot} width="6px" />
        </ImgWrapper>
      )}
    </Stationli>
  );
};

export default Station;

const FirstPin = styled.div`
  position: absolute;
  top: 50px;
  z-index: 100;
  left: 78px;
  width: 24px;
  height: 24px;
`;
const ImgWrapper = styled.div`
  position: absolute;
  top: -5px;
  left: 87px;
`;
interface StationBoxProps {
  position?: number | undefined;
  departureTime?: string | null | undefined;
  order?: number;
  coursecnt?: number;
  isstudent?: number;
  selectedStation?: string;
  stationName?: string;
}

type BusIconProps = {
  isoperation: string;
};

const BusIconWrapper = styled.div<BusIconProps>`
  position: absolute;
  top: ${({ isoperation }) =>
    isoperation === "true"
      ? "70px"
      : isoperation === "isLast"
      ? "12px"
      : "47px"};
  width: 32px;
  height: 32px;
  left: 74px;
  z-index: 99;
`;

const StationBox = styled.div<StationBoxProps>`
  flex-basis: 80%;
  ${({ order, coursecnt }) =>
    order === coursecnt
      ? css`
          border-left: none;
        `
      : order === 0
      ? css`
          /* border-left: 2px solid #999999;
                  height: 40%;
                  width: 0;
                  margin: 0 auto;
                  position: absolute; */
          border-left: none;
        `
      : order !== coursecnt
      ? css`
          border-left: 2px solid #999999;
          position: absolute;
          top: 0px;
        `
      : ""};
  padding: ${({ isstudent }) =>
    isstudent === 1 ? "0 0 16px 30px" : "0 0 35px 30px"};
  position: relative;
  ::before {
    ${({ order, coursecnt, selectedStation, stationName }) =>
      order === 0
        ? css`
            content: url(${DepartureStationIcon});
            margin-left: 2px;
            margin-top: 10px;
            width: 50px;
          `
        : order === coursecnt
        ? css`
            content: url(${ArrivalStationIcon});
            margin-left: 2.5px;
            margin-top: 10px;
          `
        : css`
            content: url(${stationName === selectedStation
              ? BlueDot
              : BusStopArrow});
            margin-top: 10px;
          `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 30px;
    width: 24px;
    height: 20px;
    position: absolute;
    left: -13px;
    top: 8px;
    line-height: 1.1;
    background-color: #fff;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  ::after {
    content: "";
    position: absolute;
    display: ${({ position }) => (position === 1 ? "block" : "none")};
    width: 30px;
    height: 30px;
    background: url(${BusIconBlue}) center center no-repeat;
    left: -16px;
    z-index: 1;
    top: -8px;
  }
  ${media.desktop} {
    flex-basis: 70%;
  }

  #empty {
    margin-left: 50px;
  }
`;

interface StationLiProps {
  status?: number | undefined;
  order?: number;
  stationid?: number;
  pickupstationid?: number;
}

const Stationli = styled.li<StationLiProps>`
  display: flex;
  position: relative;
  /* background-color: ${({ stationid, pickupstationid }) =>
    stationid === pickupstationid ? "#E9F4FF" : ""}; */

  /* :first-child {
        ${StationBox} {
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                background: url(${DepartureStationIcon}) center center no-repeat;
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
        }
    }
    :last-child {
        ${StationBox} {
            border-left: 2px solid transparent;
            ::before {
                content: "";
                width: 36px;
                height: 40px;
                border-radius: 0;
                border: none;
                background: url(${ArrivalStationIcon}) center center no-repeat;
                background-size: 100%;
                top: -14px;
                left: -19px;
            }
            ::after {
                top: -9px;
                margin-top: 0;
            }
        }
    } */
`;

interface TimeBoxProps {
  arriveTime?: string | null | undefined;
}

const TimeBox = styled.div<TimeBoxProps>`
  flex-basis: 66px;
  text-align: right;
  margin-right: 16px;
  min-width: 73px;
  padding: 16px;
  ${({ arriveTime }) =>
    arriveTime !== "" &&
    css`
      /* ::after {
                content: '';
                position: absolute;
                width: 2px;
                height: 2px;
                background: #dedede;
                top: 0;
                right: 0;
            } */
    `}
`;

const TimeGroup = styled.div`
  display: inline;
  width: 66px;
  text-align: left;
  min-height: 115px;
`;

const EstimatedArrivalTime = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 36px;
`;

export const StationName = styled.div`
  padding-top: 16px;
`;

const StudentWrapper = styled.div`
  display: inline-block;
  margin-top: 4px;
  align-items: center;
`;
