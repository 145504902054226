import React from 'react'
import styled from 'styled-components'

// components
import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'
import {media} from 'styles/media'

// image
import Phone from 'assets/images/ic_phone.icon.svg'
import Bus from 'assets/images/ic_bus_icon_black.svg'

type BottomNavProps = {
  phone?: PhoneProps
  goToChangeStation: any
  operation: boolean
}

type PhoneProps = {
  name?: string
  phone?: string
}

const BottomNav: React.FC<BottomNavProps> = props => {
  const handleCallClick = () => {
    window.location.href = `tel: ${props.phone.phone}`
  }

  return (
    <Container>
      {props?.operation ? (
        ''
      ) : (
        <Button onClick={() => props.goToChangeStation()} style={{borderRight: '1px solid #ebebed'}}>
          <ImgWrapper>
            <Img src={Bus} width={'100%'} />
          </ImgWrapper>
          <Text fontSize={15} lineHeight={18} fontWeight={500} letterSpacing={-0.3} color={'#191919'}>
            정류장 변경
          </Text>
        </Button>
      )}

      <Button onClick={handleCallClick}>
        <ImgWrapper>
          <Img src={Phone} width={'100%'} />
        </ImgWrapper>
        <Text fontSize={15} lineHeight={18} fontWeight={500} letterSpacing={-0.3} color={'#191919'}>
          동승 매니저
        </Text>
      </Button>
    </Container>
  )
}

export default BottomNav

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  border-top: 1px solid #ebebeb;
  background: #f9f9fa;
  display: flex;
  position: fixed;
  bottom: 0px;

  ${media.tablet} {
    display: none;
  }
`

const ImgWrapper = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
`

const Button = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 8px;
`
