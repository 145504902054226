import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'

import Header from 'components/atoms/Header'
import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'
import {media} from 'styles/media'
import Station from 'pages/ChangeStation/Station'

import {getCourse} from 'service/Course'
import {CDateTime} from 'model/Date'
import {patchScheduleStation} from 'service/ChangeStation'

// image
import DownArrow from 'assets/images/ic_down_line_arrow.svg'
import CourseFirstPin from 'assets/images/ic_course_start_pin.svg'
import CourseFirstLongPin from 'assets/images/ic_course_start_long_pin.svg'
import CloseButton from 'assets/images/ic_close_icon.svg'
import HalfPopUp from 'components/commons/HalfPopUp'
import SubmitModal from './SubmitModal'
import {CScheduleStation} from '../../model/ScheduleStation'

type SelectionProps = {
  id: number
  time?: string
  name: string
}

function ChangeStation() {
  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(height, '*')
    }

    window.onresize = sendHeight
    setTimeout(() => {
      sendHeight()
    }, 500)
  })

  const location = useLocation()
  const navigate = useNavigate()

  const academyID = JSON.parse(localStorage.getItem('academyID'))
  const scheduleID = location.state.scheduleID
  const dispatchCode = location.state.dispatchCode

  const [stationData, setStationData] = useState<CScheduleStation>(null)
  const [courses, setCourses] = useState([])
  const [firstStudent, setFirstStudent] = useState<number>(0)
  const [selectedstation, setSelectedstation] = useState<SelectionProps>({
    id: 0,
    time: '',
    name: '',
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [confirmValidTime, setConfirmValidTime] = useState<boolean>(false)

  const startTime = CDateTime.create(`${stationData?.scheduleDate}T${stationData?.startTime}`)
  const endTime = CDateTime.create(`${stationData?.scheduleDate}T${stationData?.endTime}`)

  const [selectedTime, setSelectedTime] = useState<CDateTime>(null)

  useEffect(() => {
    if (selectedstation.time) {
      const time = CDateTime.create(`${stationData?.scheduleDate}T${selectedstation?.time}`)
      setSelectedTime(time)
    }
  }, [selectedstation])

  const changeselectedstation = (id: number, time: string, name: string) => {
    setSelectedstation({id: id, time: time, name: name})
  }

  const getDispatchDetail = useCallback(async () => {
    await getCourse(scheduleID, dispatchCode, academyID)
      .then(res => {
        setStationData(res.scheduleStation)
        setCourses(res.courses)
        setSelectedstation(
          res.scheduleStation.scheduleType === '등원'
            ? {
                id: res.scheduleStation.departureStationId,
                time: res.scheduleStation.startTime,
                name: res.scheduleStation.departureStationName,
              }
            : {
                id: res.scheduleStation.arrivalStationId,
                time: res.scheduleStation.endTime,
                name: res.scheduleStation.arrivalStationName,
              },
        )
      })
      .catch(error => error)
  }, [])

  const putChangeStationName = () => {
    patchScheduleStation(
      dispatchCode,
      stationData.scheduleType,
      stationData.scheduleType === '등원' ? selectedstation.id : stationData.departureStationId,
      stationData.scheduleId,
      stationData.scheduleType === '하원' ? selectedstation.id : stationData.arrivalStationId,
      academyID,
    )
      .then(res => {
        if (res === `OK`) {
          setIsOpen(false)
          setConfirm(true)
        }
      })
      .catch(error => {
        if (error.response.data.status === -1) {
          setIsOpen(false)
          setConfirmValidTime(true)
        }
      })
  }

  useEffect(() => {
    getDispatchDetail()
  }, [scheduleID, dispatchCode])

  useEffect(() => {
    if (courses?.length > 0) {
      setFirstStudent(courses[0].station.studenets?.length)
    }
  }, [courses])

  return (
    stationData !== null && (
      <Container>
        <Header title={'승하차 정류장 변경'} close={'false'} route={'/info'} />
        <StationWrapper>
          <Text
            color={'#708185'}
            fontSize={14}
            fontWeight={700}
            letterSpacing={-0.28}>{`${stationData.scheduleType}하실 정류장을 선택해 주세요.`}</Text>
          <StationInfo>
            <TimeWrapper>
              <Text fontSize={14} fontWeight={500} letterSpacing={-0.28} lineHeight={46}>
                {stationData.scheduleType === '등원' && selectedTime !== null
                  ? `${selectedTime.getAmPm()} ${selectedTime.getTime()}`
                  : `${startTime.getAmPm()} ${startTime.getTime()}`}
              </Text>
              <ImgWrapper>
                <Img src={DownArrow} width={'100%'} />
              </ImgWrapper>
              <Text fontSize={14} fontWeight={500} letterSpacing={-0.28} lineHeight={46}>
                {stationData.scheduleType === '하원' && selectedTime !== null
                  ? `${selectedTime.getAmPm()} ${selectedTime.getTime()}`
                  : `${endTime.getAmPm()} ${endTime.getTime()}`}
              </Text>
            </TimeWrapper>
            <StationNameWrapper>
              <DepartureBorderBox type={stationData?.scheduleType}>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={18}
                  color={stationData.scheduleType === '등원' ? '#191919' : '#999999'}>
                  {stationData.scheduleType === '등원' ? selectedstation.name : stationData.departureStationName}
                </Text>
              </DepartureBorderBox>
              <ArrivalBorderBox type={stationData.scheduleType}>
                <Text
                  fontSize={16}
                  fontWeight={500}
                  lineHeight={20}
                  color={stationData.scheduleType === '등원' ? '#999999' : '#191919'}>
                  {stationData.scheduleType === '하원' ? selectedstation.name : stationData.arrivalStationName}
                </Text>
              </ArrivalBorderBox>
            </StationNameWrapper>
          </StationInfo>
        </StationWrapper>

        <CourseContainer>
          <TitleWrapper>
            <Text color="#708185" fontSize={12}>
              예정 시간
            </Text>
            <Text color="#708185" fontSize={12}>
              정류장
            </Text>
          </TitleWrapper>

          <Wrapper>
            {courses?.length > 0 && (
              <PinImgWrapper>
                <Img src={firstStudent === 1 ? CourseFirstLongPin : CourseFirstPin} width={'6px'} alt="firstpin" />
              </PinImgWrapper>
            )}

            {courses?.length > 0 &&
              courses?.map((detail: any, idx: number) => {
                return (
                  <Station
                    type={stationData.scheduleType}
                    key={`detail-${idx}`}
                    order={idx}
                    detail={detail}
                    busDetail={courses[idx]}
                    isLast={courses.length - 1 === idx}
                    detailsCnt={courses.length}
                    selectedstation={selectedstation}
                    exceptstation={
                      stationData.scheduleType === '등원'
                        ? stationData.arrivalStationName
                        : stationData.departureStationName
                    }
                    changeselectedstation={changeselectedstation}
                  />
                )
              })}
          </Wrapper>
          <SubmitButton onClick={() => setIsOpen(true)}>입력 완료</SubmitButton>
        </CourseContainer>

        {isOpen && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            width="600px"
            id={isOpen}
            onClose={() => setIsOpen(false)}
            contents={
              <SubmitModal
                stationData={stationData}
                onClose={() => setIsOpen(false)}
                selectedstation={selectedstation}
                putChangeStationName={putChangeStationName}
              />
            }
          />
        )}

        {confirm && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            width="400px"
            top="25%"
            left="25%"
            id={confirm}
            onClose={() => setConfirm(false)}
            contents={
              <ConfirmModal
                onClose={() => {
                  setConfirm(false)
                  navigate(-1)
                }}
              />
            }
          />
        )}

        {confirmValidTime && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            width="400px"
            top="25%"
            left="25%"
            id={confirmValidTime}
            onClose={() => setConfirmValidTime(false)}
            contents={
              <ConfirmValidTimeModal
                onClose={() => {
                  setConfirm(false)
                  navigate(-1)
                }}
              />
            }
          />
        )}
      </Container>
    )
  )
}

export default ChangeStation

type ModalProps = {
  onClose?: () => void
}

const ConfirmModal = (props: ModalProps) => {
  const {onClose} = props

  return (
    <ModalContainer>
      <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
        정류장 변경이 완료되었습니다.
      </Text>
      <Button onClick={() => onClose()}>확인</Button>
      <CloseImg onClick={() => onClose()}>
        <Img src={CloseButton} width={'100%'} alt="close" />
      </CloseImg>
    </ModalContainer>
  )
}

const ConfirmValidTimeModal = (props: ModalProps) => {
  const {onClose} = props
  return (
    <ModalContainer>
      <ConfirmTextWrapper>
        <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
          운행 시작 1시간 전까지만&nbsp;
        </Text>
        <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
          변경 가능합니다.
        </Text>
      </ConfirmTextWrapper>
      <Button onClick={() => onClose()}>
        <Text fontSize={16} fontWeight={500} lineHeight={22} letterSpacing={-0.32} color="#191919">
          확인
        </Text>
      </Button>
      <CloseImg onClick={() => onClose()}>
        <Img src={CloseButton} width={'100%'} alt="close" />
      </CloseImg>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  padding: 60px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 60px;
  position: relative;
`

const ConfirmTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    flex-direction: row;
  }
`

const Button = styled.button`
  border: 1px solid #2297ff;
  background: #2297ff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  font-family: Pretendard;
  height: 48px;
  color: #fff;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
  }
`

const CloseImg = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`

const Container = styled.div`
  background: #fff;

  ${media.tablet} {
    background: #f7f8f9;
    height: 100vh;
    padding: 23px 0px;
  }
`

const StationWrapper = styled.div`
  margin-top: 58px;
  background: #f7f7f7;
  padding: 16px;

  ${media.tablet} {
    background: #fff;
    margin: 16px 0px;
    border-radius: 8px;
  }
`

const StationInfo = styled.div`
  display: grid;
  grid-template-columns: 70px calc(100% - 70px);
  align-items: center;
  margin-top: 16px;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-left: 16px;
`

const ImgWrapper = styled.div`
  width: 12px;
  height: 12px;
`

type BorderBoxProps = {
  type: string
}

const DepartureBorderBox = styled.div<BorderBoxProps>`
  width: 100%;
  min-height: 46px;
  max-height: 46px;
  padding: 12px;
  border-radius: 8px;
  border: ${({type}) => (type === '등원' ? '2px solid #2297FF' : '1px solid #CECECE')};
  background: ${({type}) => (type === '등원' ? '#fff' : '#f9f9f9')};
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ArrivalBorderBox = styled.div<BorderBoxProps>`
  width: 100%;
  min-height: 46px;
  max-height: 46px;
  padding: 12px;
  border-radius: 8px;
  border: ${({type}) => (type === '등원' ? '1px solid #CECECE' : '2px solid #2297FF')};
  background: ${({type}) => (type === '등원' ? '#f9f9f9' : '#fff')};
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CourseContainer = styled.div`
  background: #fff;
  padding: 24px 0px 0px;

  ${media.tablet} {
    border-radius: 8px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  column-gap: 61px;
  padding: 0 16px;
`

const Wrapper = styled.ul`
  width: 100%;
  position: relative;
  background: #fff;
`

const PinImgWrapper = styled.div`
  z-index: 11;
  position: absolute;
  top: 57px;
  left: 87px;
`

const SubmitButton = styled.button`
  width: calc(100% - 32px);
  min-height: 48px;
  padding: 10px;
  margin: 0px 16px 24px;
  border: 1px solid #2297ff;
  border-radius: 8px;
  background: #2297ff;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  font-family: Pretendard;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #2297ff;
    cursor: pointer;
  }
`
