import axios, {AxiosRequestConfig} from 'axios'
import {AxiosEnum} from 'enum/AxiosEnum'
import {API} from '../Env'

export async function handleToggle(scheduleID: number, studentID: number, boardSwitch: boolean, academyID: string) {
  const url = `${API}/${academyID}/schedules/${scheduleID}/boarding-switch`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.PUT.value,
    data: {boardSwitch: boardSwitch},
    params: {
      academyId: academyID,
      scheduleId: scheduleID,
    },
  }

  const axiosResponse = await axios(config)

  const result = axiosResponse.data.code

  return result
}

export async function getBoarding(academyID: string, scheduleID: number) {
  const url = `${API}/${academyID}/schedules/${scheduleID}/boarding-switch`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
  }

  const axiosResponse = await axios(config)

  const result = axiosResponse.data.data

  return result
}
