import React, {useState, useEffect, useCallback} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import styled from 'styled-components'

// image
import DownArrow from 'assets/images/ic_down_line_arrow.svg'
import BusIcon from 'assets/images/ic_gray_bus_icon.svg'

// components
import Img from 'components/atoms/Image'
import {media} from 'styles/media'

import Text from 'components/atoms/Span'
import {getSchedule} from 'service/Schedule'
import {handleToggle} from 'service/Toggle'
import {CSchedule, CScheduleMap} from 'model/Schedule'
import {CDateTime} from 'model/Date'
import Loading from 'components/commons/Loading'

const ToggleBox = ({status, scheduleId, getStudentSchedule, operationBus}) => {
  const [isToggle, setIsToggle] = useState(status.toString())

  const academyId = JSON.parse(localStorage.getItem('academyID'))
  const studentId = JSON.parse(localStorage.getItem('studentID'))

  const handleBoardSwitch = (scheduleId: number, studentId: number, isToggle: string) => {
    handleToggle(scheduleId, studentId, !JSON.parse(isToggle), academyId)
      .then(res => {
        if (res === `OK`) {
          if (isToggle === 'true') {
            setIsToggle('false')
          }
          if (isToggle === 'false') {
            setIsToggle('true')
          }
        }
      })

      .catch(error => error)
  }

  return (
    <Toggle
      status={isToggle}
      onClick={operationBus ? () => {} : () => handleBoardSwitch(scheduleId, studentId, isToggle)}
      operationBus={operationBus}>
      <SpanWrapper status={isToggle} operationBus={operationBus}>
        {isToggle === 'true' ? '타요' : '안타요'}
      </SpanWrapper>
      <CircleIcon status={isToggle} operationBus={operationBus} />
    </Toggle>
  )
}

export const ShuttleBus = () => {
  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(height, '*')
    }

    window.onresize = sendHeight

    setTimeout(() => {
      sendHeight()
    }, 500)
  })

  const [scheduleMaps, setScheduleMaps] = useState<CScheduleMap[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const studentName =
    localStorage.getItem('studentName') === null || localStorage.getItem('studentName') === undefined
      ? location.state?.studentName
      : JSON.parse(localStorage.getItem('studentName'))

  const academyId = JSON.parse(localStorage.getItem('academyID'))
  const studentId = JSON.parse(localStorage.getItem('studentID'))

  const goToBusDetailPage = (details: CSchedule) => {
    navigate('/info', {
      state: {
        scheduleID: details.scheduleId,
        dispatchCode: details.dispatchCode,
      },
    })
    localStorage.setItem('dispatchCode', JSON.stringify(details.dispatchCode))
    localStorage.setItem('scheduleID', JSON.stringify(details.scheduleId))
    localStorage.setItem('boardSwitch', JSON.stringify(details.boardSwitch))
  }

  const goToChangeSchedule = (details: CSchedule) => {
    navigate('/modify/schedule', {
      state: {
        scheduleID: details.scheduleId,
        dispatchCode: details.dispatchCode,
      },
    })
  }

  const getStudentSchedule = useCallback(() => {
    setLoading(true)

    if (academyId !== undefined) {
      getSchedule(studentId, academyId)
        .then(res => {
          setLoading(false)
          setScheduleMaps(res)
        })

        .catch(error => {
          throw new Error(`getSchedule() failed. (aid: ${academyId}, sid: ${studentId}, error: ${error})`)
        })
    }
  }, [studentId, academyId])

  useEffect(() => {
    window.localStorage.removeItem('dispatchCode')
    window.localStorage.removeItem('scheduleID')

    if (academyId !== undefined && studentId !== undefined) {
      getStudentSchedule()
    }

    getStudentSchedule()
    let i = 0;
    let raf;
    const callback = function () {
        i++;
        if (i%3600 === 0){
            getStudentSchedule()
        }
        //console.log("22#!")
        if (i >= 36000000) {
            console.log('end')
            cancelAnimationFrame(raf);
            return;
        }
        raf = requestAnimationFrame(callback);
          //console.log(i++);
    }
    requestAnimationFrame(callback);
    /*
    setInterval(() => {
      getStudentSchedule()
    }, 60000)
      */
  }, [academyId, studentId])

  return loading ? (
    <Loading />
  ) : (
    academyId !== undefined && studentId !== undefined && (
      <Container>
        <TitleText>
          {studentName === null || studentName === undefined ? '옹옹옹' : studentName} 학생의 셔틀 버스
        </TitleText>

        {/* 스케줄 박스 */}
        {scheduleMaps?.length > 0 && !loading ? (
          <BoxWrapper>
            {scheduleMaps.map((scheduleMap, idx) => (
              <div key={idx}>
                <TextWrapper>
                  <Text fontSize={16} lineHeight={22} fontWeight={700} color={'#708185'}>
                    {`${scheduleMap.date.getMonth()}월 ${scheduleMap.date.getDay()}일 ${
                      scheduleMap.date.getDayOfTheWeek().exposure
                    }요일`}
                  </Text>
                </TextWrapper>
                <WebBorder>
                  {scheduleMap.schedules.map((item: CSchedule, idx: number) => {
                    const startTime = item.startTime.getTime()
                    const now = CDateTime.now()
                    return (
                      <Wrapper key={idx}>
                        <Box>
                          <BusWrapper status={item.startBus}>
                            <BusName>
                              <BoardTitle>
                                <Text
                                  fontSize={12}
                                  lineHeight={17}
                                  letterSpacing={-0.24}
                                  color={!item.startBus ? '#708185' : '#2297FF'}>
                                  {item.startBus ? '운행중' : '운행전'}
                                </Text>
                              </BoardTitle>
                              <Text fontSize={16} lineHeight={22} fontWeight={500} color="#fff">
                                {item.busName}
                              </Text>
                            </BusName>
                          </BusWrapper>

                          <BusInfo>
                            <ToggleWrapper>
                              <Noti>
                                <NameSpan>
                                  {item.scheduleType === '등원' ? item.departureStationName : item.arrivalStationName}
                                </NameSpan>
                                <Text fontSize={12} lineHeight={18} color="#708185">
                                  5분 전에 나와서 기다리는 센스!
                                </Text>
                              </Noti>
                              <ToggleBox
                                status={item?.boardSwitch === 'WILL_BOARD' ? true : false}
                                scheduleId={item.scheduleId}
                                getStudentSchedule={getStudentSchedule}
                                operationBus={item.operationBus}
                              />
                            </ToggleWrapper>

                            <Summary>
                              <TimeWrapper>
                                <TimeSpan>
                                  <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                                    {item.startTime.getAmPm()}
                                  </Text>
                                  <Text fontSize={24} fontWeight={500} lineHeight={30} letterSpacing={-0.48}>
                                    {startTime}
                                  </Text>
                                </TimeSpan>
                                <ImgWrapper>
                                  <Img src={DownArrow} width={'20px'} height={'20px'} alt="arrow" />
                                </ImgWrapper>
                                <TimeSpan>
                                  <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                                    {item.endTime.getAmPm()}
                                  </Text>
                                  <Text fontSize={24} fontWeight={500} letterSpacing={-0.48} lineHeight={30}>
                                    {item.endTime.getTime()}
                                  </Text>
                                </TimeSpan>
                              </TimeWrapper>
                              <StationWrapper>
                                <StationSpan>{item.departureStationName}</StationSpan>
                                <StationSpan>{item.arrivalStationName}</StationSpan>
                              </StationWrapper>
                            </Summary>
                            <ButtonWrapper>
                              <DetailButton onClick={() => goToBusDetailPage(item)}>노선 자세히 보기</DetailButton>
                              <DetailButton
                                onClick={item.operationBus ? () => {} : () => goToChangeSchedule(item)}
                                disabled={item.operationBus}>
                                셔틀 버스 예약 변경
                              </DetailButton>
                            </ButtonWrapper>
                          </BusInfo>
                        </Box>
                      </Wrapper>
                    )
                  })}
                </WebBorder>
                <BorderLine />
              </div>
            ))}
          </BoxWrapper>
        ) : (
          <EmptyContainer>
            <EmptyWrapper>
              <Img src={BusIcon} width={'80px'} />
              <Text fontSize={16} fontWeight={700} lineHeight={22} letterSpacing={-0.32} color="#708185">
                예약된 셔틀버스가 없습니다.
              </Text>
            </EmptyWrapper>
          </EmptyContainer>
        )}
      </Container>
    )
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding-bottom: 0.1px;

  ${media.tablet} {
    background: #f7f8f9;
  }
`

const Wrapper = styled.div`
  margin-bottom: 16px;

  ${media.tablet} {
    margin-bottom: 0;
  }
`

const BorderLine = styled.div`
  width: 100%;
  height: 16px;
  background: #f7f8f9;
`

const BoxWrapper = styled.div`
  padding-top: 72px;
  width: 100%;

  ${media.tablet} {
    padding-top: 94px;
  }
`

const TextWrapper = styled.div`
  margin: 16px;
  ${media.tablet} {
    margin: 16px 0;
  }
`

const WebBorder = styled.div`
  ${media.tablet} {
    border-radius: 8px;
  }
`
const Box = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 0px 16px;

  ${media.tablet} {
    background: #fff;
    padding: 16px;
  }
`

type ToggleWrapperProps = {
  status: boolean
}
const BusWrapper = styled.div<ToggleWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: ${({status}) => (!status ? '#C9CDD2' : '#2297FF')};
  padding: 10px 16px;
  border-radius: 8px 8px 0px 0px;
  border: ${({status}) => (!status ? '1px solid #C9CDD2' : '1px solid #2297FF')};
`

const BusName = styled.div`
  display: flex;
  align-items: center;
`

const BoardTitle = styled.div`
  height: 17px;
  padding: 0 4px;
  margin-right: 8px;
  border-radius: 4px;
  background: #e9f4ff;
`

type ToggleProps = {
  status: string
  operationBus: boolean
}

const Toggle = styled.div<ToggleProps>`
  display: flex;
  width: 72px;
  height: 32px;
  padding: ${({status}) => (status === 'true' ? '4px 4px 4px 6px' : '4px 6px 4px 4px')};
  background: ${({status, operationBus}) => (operationBus ? '#ebebed' : status === 'true' ? '#2297FF' : '#C9CDD2')};
  color: #9ea4aa;
  border-radius: 100px;
  align-items: center;
  cursor: pointer;
`

const Noti = styled.div`
  display: flex;
  flex-direction: column;
`

const NameSpan = styled.div`
  overflow: hidden;
  max-width: 100%;
  display: block;
  height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
`

const BusInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #fff;
  border: 1px solid #e9f4ff;
  border-radius: 0px 0px 8px 8px;
  row-gap: 16px;
`

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 72px) 72px;
  justify-content: space-between;
  align-items: center;
`

const Summary = styled.div`
  display: flex;
  width: 100%;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  column-gap: 5px;
`

const TimeSpan = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: center;
  align-items: flex-end;
  min-width: 101px;
`

const StationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 116px);
`

const StationSpan = styled.div`
  max-width: 100%;
  display: block;
  height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
`

const ImgWrapper = styled.div`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

type ButtonProps = {
  disabled?: boolean
}

const DetailButton = styled.div<ButtonProps>`
  width: 100%;
  border: ${props => (props.disabled ? '1px solid #ebebeb' : '1px solid #fff')};
  background: ${props => (props.disabled ? '#ebebed' : '#e9f4ff')};
  color: ${props => (props.disabled ? '#72787f' : '#000')};
  padding: 10px 10px;
  font-family: Pretendard;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  transition: all 0.2s;

  &:hover {
    background: ${props => (props.disabled ? '#ebebed' : '#fff')};
    border: ${props => (props.disabled ? '1px solid #ebebed' : '1px solid #2297ff')};
    color: ${props => (props.disabled ? '#72787f' : '#2297ff')};
  }
`

type SpanProps = {
  status: string
  operationBus: boolean
}
const SpanWrapper = styled.div<SpanProps>`
  width: 38px;
  text-align: center;
  transform: ${({status}) => (status === 'true' ? 'translateX(0)' : 'translateX(24px)')};
  transition: transform 0.5s ease;
  color: ${props => (props.operationBus ? '#9ea4aa' : '#fff')};
  font-size: 12px;
  font-weight: 600;
`

type CircleProps = {
  status: string
  operationBus: boolean
}
const CircleIcon = styled.div<CircleProps>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${props => (props.operationBus ? '#9ea4aa' : '#fff')};
  transform: ${({status}) => (status === 'true' ? 'translateX(0px)' : 'translateX(-38px)')};
  transition: transform 0.5s ease;
`

const EmptyContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 72px 16px 0;

  ${media.tablet} {
    padding-top: 94px;
    height: calc(100vh - 72px);
    /* height: 248px; */
    display: block;
    overflow: hidden;
  }
`

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 154px;
  row-gap: 12px;
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 16px 16px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
`

const TitleText = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  padding: 12px 16px;

  ${media.tablet} {
    padding: 32px 0;
    line-height: 30px;
    background: #f7f8f9;
  }
`
