import axios, {AxiosRequestConfig} from 'axios'
import {AxiosEnum} from 'enum/AxiosEnum'
import {API} from '../Env'

export async function getBusServiceDetailList(academyID: string, dispatchCode: string, date: string) {
  const url = `${API}/${academyID}/bus-operations/detail`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {code: dispatchCode, date},
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}
