import React from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

// components
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import {media} from 'styles/media'

// image
import LeftArrow from 'assets/images/ic_left_arrow.svg'

const Header = (props: any) => {
  const navigate = useNavigate()
  const {summary} = props

  const historyState = localStorage.getItem('historyState')

  const historyStateBack = () => {
    window.parent.postMessage({type: 'navigateBack', payload: '/'}, '*')
    localStorage.removeItem('historyState')
  }

  return (
    <Wrapper>
      <Img
        src={LeftArrow}
        className="arrow"
        width={'20px'}
        alt="left"
        onClick={historyState !== null ? historyStateBack : () => navigate('/schedules')}
      />
      <BusName>
        <BoardTitle>
          <Text fontSize={12} lineHeight={17} color={!summary?.startBus ? '#708185' : '#2297ff'}>
            {summary?.startBus ? '운행중' : '운행전'}
          </Text>
        </BoardTitle>
        <Text fontSize={16} lineHeight={20} fontWeight={500}>
          {summary?.busName}
        </Text>
      </BusName>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  display: flex;
  padding: 19px 16px;
  justify-content: center;
  column-gap: 16px;
  border-bottom: 0.5px solid #ebebed;
  background: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;

  ${media.tablet} {
    width: 100%;
    border-radius: 8px;
    border-bottom: none;
    justify-content: flex-start;
    padding-left: 52px;
    position: relative;
  }
  .arrow {
    position: absolute;
    top: 19px;
    left: 16px;
    cursor: pointer;
  }
`

const BusName = styled.div`
  display: flex;
  align-items: center;
`

const BoardTitle = styled.div`
  padding: 0px 4px;
  border-radius: 4px;
  background: #e9f4ff;
  margin-right: 8px;
`
