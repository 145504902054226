// @ts-ignore
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {MapContainer, Marker, TileLayer} from 'react-leaflet'
import L from 'leaflet'

// components
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import {media} from 'styles/media'
import BackButton from 'assets/images/ic_left_arrow.svg'
import SearchIcon from 'assets/images/ic_search_icon.svg'
import Warning from 'assets/images/ic_warning_icon.svg'

// image
import Close from 'assets/images/ic_close_icon.svg'
import DepartureStationIcon from 'assets/images/ic_departure_station.svg'
import StationIcon from 'assets/images/ic_station_icon.svg'
import {getScheduleStation} from 'service/Schedule'
import {CStationList} from 'model/StationList'
import {CScheduleStation} from '../../model/ScheduleStation'

type SelectionProps = {
  id: number
  name: string
  lat: number
  lng: number
}

type SelectStationProps = {
  stationId: number
  address: string
  name: string
}

type MapProps = {
  onClose?: () => void
  handleSelectStation?: any
  scheduleID: number
  station: SelectionProps
  stationData: CScheduleStation
}

const SearchStation: React.FC<MapProps> = ({onClose, handleSelectStation, scheduleID, station, stationData}) => {
  const academyID = JSON.parse(localStorage.getItem('academyID'))
  const [center, setCenter] = useState({lat: station.lat, lng: station.lng})

  useEffect(() => {
    setCenter({lat: station.lat, lng: station.lng})
  }, [station])

  const [stationList, setStationList] = useState<CStationList[]>([])
  const [selectedStation, setSelectedStation] = useState<SelectStationProps>({
    stationId: stationData?.scheduleType === '등원' ? stationData.departureStationId : stationData.arrivalStationId,
    address: '',
    name: '',
  })
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [searchList, setSearchList] = useState<CStationList[]>([])

  const getStationList = useCallback(async () => {
    await getScheduleStation(scheduleID, academyID)
      .then(sl => {
        setStationList(sl)
      })
      .catch(error => error)
  }, [])

  useEffect(() => {
    getStationList()
  }, [scheduleID])

  const stationAddress = stationList.filter(item => item.stationName === station.name)

  useEffect(() => {
    if (stationList?.length > 0) {
      setCenter({lat: stationAddress[0].lat, lng: stationAddress[0].lng})
    }
  }, [stationList])

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  useEffect(() => {
    if (keyword.length > 0) {
      const list = stationList.filter(item => item.stationName.includes(keyword))
      setSearchList(list)
    }

    if (keyword.length < 1) {
      setSearchList([])
    }
  }, [keyword])

  const handleChangeStation = (item: CStationList) => {
    setSelectedStation({
      stationId: item.stationId,
      address: item.address,
      name: item.stationName,
    })
    setCenter({lat: item.lat, lng: item.lng})
  }

  return (
    center.lat > 0 && (
      <Container>
        <Header>
          <BackImg onClick={() => onClose()}>
            <Img src={BackButton} width={'100%'} alt="close" />
          </BackImg>
          <Text fontSize={16} lineHeight={20} letterSpacing={-0.32} fontWeight={500}>
            정류장 검색
          </Text>
          <CloseImg onClick={() => onClose()}>
            <Img src={Close} width={'100%'} alt="close" />
          </CloseImg>
        </Header>

        <div className="map_container">
          <MapContainer
            // @ts-ignore
            center={[center.lat, center.lng]}
            zoom={16}
            className="map">
            <TileLayer
              // @ts-ignore
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {stationList?.map((selectStation: any, idx: number) => {
              const lat: number = parseFloat(selectStation.lat)
              const lng: number = parseFloat(selectStation.lng)
              const stationLength = stationList.length
              const stationId = Number(selectStation.stationId)
              return (
                <Marker
                  key={stationId}
                  position={[lat, lng]}
                  eventHandlers={{
                    click: () => {
                      setSelectedStation({
                        stationId: selectStation.stationId,
                        address: selectStation.address,
                        name: selectStation.stationName,
                      })
                    },
                  }}
                  // @ts-ignore
                  icon={getStationIcon(idx, station.name, selectStation, stationLength, selectedStation.name)}
                />
              )
            })}
          </MapContainer>
        </div>
        {/* 검색 인풋창 */}
        <SearchWrapper $focused={isFocused}>
          <SearchImgWrapper>
            <Img src={SearchIcon} width={'100%'} height={'100%'} />
          </SearchImgWrapper>
          <SearchInput
            placeholder="정류장명을 검색해 주세요."
            onChange={e => setKeyword(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </SearchWrapper>

        {/* 검색 결과 리스트 */}
        {isFocused ? (
          <SearchListContainer listcnt={searchList.length}>
            {searchList.length === 0 ? (
              <EmptyWrapper>
                <WarningImg>
                  <Img src={Warning} width={'100%'} height={'100%'} />
                </WarningImg>
                <Text fontSize={16} fontWeight={500} lineHeight={22} color="#708185">
                  검색 결과가 없습니다.
                </Text>
              </EmptyWrapper>
            ) : (
              searchList.map((item: CStationList, idx: number) => (
                <Station onMouseDown={() => handleChangeStation(item)} key={`station-${idx}_${item.lat}`}>
                  <Text fontSize={16} fontWeight={500} lineHeight={16}>
                    {item.stationName}
                  </Text>
                </Station>
              ))
            )}
          </SearchListContainer>
        ) : (
          ''
        )}

        <BottomInfo>
          <div
            style={{
              height: '58px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Text color={'#2297FF'} fontSize={18} fontWeight={500} lineHeight={21} letterSpacing={-0.36}>
              {selectedStation.name === '' ? station.name : selectedStation.name}
            </Text>
            <StationNameWrapper>
              <Text color={'#fff'} fontSize={12} lineHeight={14} fontWeight={500} letterSpacing={-0.24}>
                {selectedStation.address === '' ? stationAddress[0]?.address : selectedStation.address}
              </Text>
            </StationNameWrapper>
          </div>
          <SelectButton
            onClick={() => handleSelectStation(selectedStation.stationId, scheduleID, selectedStation.name)}>
            <Text fontSize={16} fontWeight={500} lineHeight={22} color="#fff">
              선택
            </Text>
          </SelectButton>
        </BottomInfo>
      </Container>
    )
  )
}

export default SearchStation

type SearchProps = {
  $focused: boolean
}

const SearchWrapper = styled.div<SearchProps>`
  position: absolute;
  top: 74px;
  left: 16px;
  z-index: 999;
  width: calc(100% - 32px);
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  border: ${({$focused}) => ($focused ? '2px solid #2297ff' : '1px solid #cecece')};
  background: #fff;
  box-shadow: 0px 12px 15px 0px rgba(0, 0, 0, 0.08);
  display: flex;
`

const SearchImgWrapper = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`

const SearchInput = styled.input`
  line-height: 22px;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  width: 100%;
  border: none;
  font-family: 'Pretendard';
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

type SearchListProps = {
  listcnt: number
}

const SearchListContainer = styled.div<SearchListProps>`
  width: calc(100% - 32px);
  max-height: 240px;
  padding: ${({listcnt}) => (listcnt === 0 ? '38px 16px' : '0')};
  display: flex;
  flex-direction: column;
  background: #fff;
  overflow-y: scroll;
  position: absolute;
  top: 123px;
  left: 16px;
  border-radius: 8px;
  z-index: 99999;
  box-shadow: 0px 12px 15px 0px rgba(0, 0, 0, 0.08);
`

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
`

const Station = styled.div`
  width: 100%;
  padding: 16px;
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
  }

  :last-child {
    border-bottom: none;
  }
`

const WarningImg = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`

const BottomInfo = styled.div`
  display: flex;
  justify-content: space-between;
  bottom: 0px;
  position: absolute;
  z-index: 999999;
  width: 100%;
  padding: 20px 22px;
  border-radius: 20px 20px 0px 0px;
  background: rgba(0, 0, 0, 0.7);

  ${media.tablet} {
    bottom: 0px;
    border-radius: 20px 20px 7px 7px;
  }
`

const StationNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`

const SelectButton = styled.button`
  width: 82px;
  height: 42px;
  border: 1px solid #2297ff;
  background: #2297ff;
  border-radius: 30px;
  padding: 10px 16px;
  cursor: pointer;
`

const Container = styled.div`
  position: relative;
  ::-webkit-scrollbar {
    display: none; // chrome, safari, opera
  }
  scrollbar-width: none; // firefox

  -ms-overflow-style: none; // IE, edge

  .map_container {
    height: 100%;

    ::-webkit-scrollbar {
      display: none; // chrome, safari, opera
    }
    scrollbar-width: none; // firefox

    -ms-overflow-style: none; // IE, edge

    ${media.tablet} {
      height: calc(100% - 250px);

      ::-webkit-scrollbar {
        display: none; // chrome, safari, opera
      }
      scrollbar-width: none; // firefox

      -ms-overflow-style: none; // IE, edge
    }
  }
  .map {
    height: calc(100vh - 57px);
    border-radius: 0px 0px 30px 30px;

    ${media.tablet} {
      height: 576px;
    }
  }
  .leaflet-container .leaflet-control-attribution {
    display: none;
  }
  .leaflet-top {
    margin-top: 45%;
  }
  .leaflet-left {
    margin-left: 87%;

    ${media.fold} {
      margin-left: 80%;
    }
    ${media.tablet} {
      margin-left: 88%;
    }
  }
  .leaflet-touch .leaflet-bar a:first-child {
    width: 33px;
    height: 33px;
    border-radius: 6px 6px 0px 0px;
    color: #585858;
  }
  .leaflet-touch .leaflet-bar a:last-child {
    width: 33px;
    height: 33px;
    border-radius: 0px 0px 6px 6px;
    color: #585858;
  }
  .leaflet-touch .leaflet-bar {
    border: 2px solid rgba(0 0, 0, 0.2);
    border-radius: 6px;
  }

  .leaflet-control {
    z-index: 300;
    transform: translateY(70px);
  }
`

const Header = styled.div`
  display: flex;
  position: relative;
  padding: 18px 16px;
  border-bottom: 1px solid #ebebed;
  justify-content: center;

  ${media.tablet} {
    justify-content: space-between;
  }
`

const getStationIcon = (
  index: number,
  stationName: string,
  selectStation: CStationList,
  stationLength: number,
  selectedStationName: string,
) => {
  const icon = L.divIcon({
    className: 'station-icon',
    html: `<div className="index" style="
                width: 30px;
                background: url(${
                  selectedStationName === '' && stationName === selectStation.stationName
                    ? DepartureStationIcon
                    : selectStation.stationName !== '' && selectedStationName === selectStation.stationName
                      ? DepartureStationIcon
                      : StationIcon
                }) center center no-repeat;
                background-size:contain;
                width: ${
                  selectedStationName === '' && stationName === selectStation.stationName
                    ? '40px'
                    : selectStation.stationName !== '' && selectedStationName === selectStation.stationName
                      ? '40px'
                      : '26px'
                };
                height: ${
                  selectedStationName === '' && stationName === selectStation.stationName
                    ? '40px'
                    : selectStation.stationName !== '' && selectedStationName === selectStation.stationName
                      ? '40px'
                      : '29px'
                };
                display:inline-flex;
                align-items:center;
                justify-content:center;
                transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                position:relative;">
                <div style="color:white;font-size:12px;line-height:18px;font-weight:800;position: absolute; top: 58%; left: 50%; transform: translate(calc(-50%), calc(-50% - 3px));">${
                  index === 0 || index === stationLength - 1 ? '' : ''
                }</div><div className="name" style="display:none;width:100px;padding:10px;margin-left:-50px;background:pink">${
                  selectStation.address
                }</div></div>`,
  })
  return icon
}

const BackImg = styled.div`
  position: absolute;
  top: 19px;
  left: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  ${media.tablet} {
    display: none;
  }
`
const CloseImg = styled.div`
  position: absolute;
  top: 19px;
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`
