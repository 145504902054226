import React, {useState} from 'react'
import styled from 'styled-components'

// components
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'

import {handleToggle} from 'service/Toggle'
import {CDateTime} from 'model/Date'

// image
import RightArrow from 'assets/images/ic_right_gray_arrow.svg'
import {media} from 'styles/media'
import {CScheduleStation} from '../../model/ScheduleStation'

type SummaryProps = {
  boardingSwitch: boolean
  summary: CScheduleStation
  scheduleID: number
  fetchCourse: () => void
}

const studentId = 2961

const Summary = (props: SummaryProps) => {
  const {summary, scheduleID, fetchCourse} = props
  const academyID = JSON.parse(localStorage.getItem('academyID'))
  const [isToggle, setIsToggle] = useState(props.boardingSwitch ? 'true' : 'false')

  const startTime = CDateTime.create(summary?.scheduleDate + 'T' + summary?.startTime)
  const endTime = CDateTime.create(summary?.scheduleDate + 'T' + summary?.endTime)

  const handleBoardSwitch = (scheduleId: number, studentId: number, isToggle: string) => {
    handleToggle(scheduleId, studentId, !JSON.parse(isToggle), academyID)
      .then(res => {
        if (res === `OK`) {
          if (isToggle === 'true') {
            setIsToggle('false')
          }
          if (isToggle === 'false') {
            setIsToggle('true')
          }

          fetchCourse()
        }
      })

      .catch(error => error)
  }

  return (
    <Container>
      <BusTime>
        <SpanWrapper>
          <Text fontSize={12} color="#708185">
            운행시간
          </Text>
        </SpanWrapper>
        <Text fontSize={16} fontWeight={500}>
          {startTime.getAmPm()}
        </Text>
        &nbsp;
        <Text fontSize={18} fontWeight={500}>
          {startTime.getTime()}
        </Text>
        <ImgWrapper>
          <Img src={RightArrow} width={'100%'} alt="right" />
        </ImgWrapper>
        <Text fontSize={16} fontWeight={500}>
          {endTime.getAmPm()}
        </Text>
        &nbsp;
        <Text fontSize={18} fontWeight={500}>
          {endTime.getTime()}
        </Text>
      </BusTime>

      <BusInfo>
        <BusStation>
          <StationName>{summary?.departureStationName}</StationName>
          <ImgWrapper style={{flex: '0 0 auto'}}>
            <Img src={RightArrow} width={'100%'} alt="right" />
          </ImgWrapper>
          <StationName>{summary?.arrivalStationName}</StationName>
        </BusStation>

        <Toggle
          toggle={isToggle.toString()}
          onClick={summary?.operationBus ? () => {} : () => handleBoardSwitch(scheduleID, studentId, isToggle)}
          operationBus={summary?.operationBus}>
          <ToggleSpan toggle={isToggle} operationBus={summary?.operationBus}>
            {isToggle === 'true' ? '타요' : '안타요'}
          </ToggleSpan>
          <CircleIcon toggle={isToggle} operationBus={summary?.operationBus} />
        </Toggle>
      </BusInfo>
    </Container>
  )
}

export default Summary

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  row-gap: 8px;
  background: #f7f7f7;
  margin-top: 58.5px;

  ${media.tablet} {
    width: 100%;
    margin-top: 16px;
    background: #fff;
    border-radius: 8px;
  }
`

const BusInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

const BusTime = styled.div`
  display: flex;
  align-items: center;
`

const BusStation = styled.div`
  width: calc(100% - 86px);
  display: flex;
  align-items: center;
`

const StationName = styled.div`
  max-width: 100%;
  display: block;
  height: 17px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`

const SpanWrapper = styled.div`
  margin-right: 9px;
`

const ImgWrapper = styled.div`
  margin: 0px 10px;
  width: 12px;
  height: 12px;
`

type ToggleProps = {
  toggle?: string
  operationBus: boolean
}
const Toggle = styled.div<ToggleProps>`
  display: flex;
  width: 72px;
  height: 32px;
  padding: ${({toggle}) => (toggle ? '4px 4px 4px 6px' : '4px 6px 4px 4px')};
  background: ${({toggle, operationBus}) => (operationBus ? '#ebebed' : toggle === 'true' ? '#2297FF' : '#C9CDD2')};
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
`

type SpanProps = {
  toggle?: string
  operationBus: boolean
}
const ToggleSpan = styled.div<SpanProps>`
  width: 38px;
  text-align: center;
  transform: ${({toggle}) => (toggle === 'true' ? 'translateX(0)' : 'translateX(22px)')};
  transition: transform 0.5s ease;
  color: ${props => (props?.operationBus ? '#9ea4aa' : '#fff')};
  font-size: 12px;
  font-weight: 600;
`

type CircleProps = {
  toggle?: string
  operationBus: boolean
}
const CircleIcon = styled.div<CircleProps>`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${props => (props?.operationBus ? '#9ea4aa' : '#fff')};
  transform: ${({toggle}) => (toggle === 'true' ? 'translateX(4px)' : 'translateX(-38px)')};
  transition: transform 0.5s ease;
`
