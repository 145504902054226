import React from 'react'
import styled, {css} from 'styled-components'

// componenets
import {media} from 'styles/media'
import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'

// image
import DepartureStationIcon from 'assets/images/ic_departure_station_icon.svg'
import ArrivalStationIcon from 'assets/images/ic_arrival_station_icon.svg'
import BusStopArrow from 'assets/images/ic_course_arrow_icon.svg'
import BusIconBlue from 'assets/images/ic_bus_icon_blue.svg'
import Dot from 'assets/images/ic_gray_dot.svg'
import {CScheduleStation} from 'model/ScheduleStation'

type StationProps = {
  order?: number
  detail?: any
  busDetail?: any
  isLast: number
  detailsCnt?: number
  existIdx?: number
  summary?: CScheduleStation
}

const Station: React.FC<StationProps> = props => {
  const {order, detail, isLast, detailsCnt, existIdx, busDetail, summary} = props
  const stationId = Number(detail.station.id)

  const coursecnt = detailsCnt - 1

  const busData = busDetail[existIdx]

  return (
    <Stationli
      key={detail.id}
      order={order}
      stationid={stationId}
      pickupstationid={detail?.station?.students[0]?.pickUpStationId}>
      <TimeBox arriveTime={detail.arriveTime}>
        <TimeGroup>
          <EstimatedArrivalTime>{detail.station.time}</EstimatedArrivalTime>
        </TimeGroup>
      </TimeBox>

      {order === existIdx && (
        <BusIconWrapper
          isoperation={existIdx}
          isLast={isLast}
          departureTime={busData.departureTime}
          arriveTime={busData.arriveTime}>
          <Img src={BusIconBlue} width={'100%'} height={'100%'} alt="bus" />
        </BusIconWrapper>
      )}

      <StationBox order={order} coursecnt={Number(coursecnt)} isstudent={detail.station.students?.length}>
        <StationName>{detail.station?.name}</StationName>
        {detail.station.students?.length > 0 && stationId === detail.station.students[0].pickUpStationId && (
          <StudentWrapper border={detail.station.students[0].timeOnBoard.toString()}>
            <Text fontSize={14} fontWeight={700} lineHeight={24} letterSpacing={-0.28}>
              {detail.station.students[0].name}
            </Text>{' '}
            <Text fontSize={12} lineHeight={24} color="#585858" letterSpacing={-0.24}>
              {detail.station.students[0].boardSwitch === false
                ? '안타요'
                : detail.station.students[0].boardSwitch === true && detail.station.students[0].timeOnBoard === true
                  ? '승차'
                  : detail.station.students[0].boardSwitch === true && summary.status === 3
                    ? '미승차'
                    : '승차예정'}
            </Text>
          </StudentWrapper>
        )}
        {detail.station.students?.length > 0 &&
          stationId === detail.station.students[0].takeOffStationId &&
          detail.station.students[0].boardSwitch === true &&
          summary.status !== 3 && (
            <StudentWrapper>
              <Text fontSize={14} fontWeight={700} lineHeight={24} letterSpacing={-0.28}>
                {detail.station.students[0].name}
              </Text>{' '}
              <Text fontSize={12} lineHeight={24} color="#585858" letterSpacing={-0.24}>
                {detail.station.students[0].timeOffBoard === false ? '하차 예정' : '하차'}
              </Text>
            </StudentWrapper>
          )}
      </StationBox>
      {order === Number(coursecnt) && (
      <ImgWrapper>
          <Img src={Dot} width={'6px'} alt="dot" />
        </ImgWrapper>
      )}
    </Stationli>
  )
}

export default Station

const ImgWrapper = styled.div`
  position: absolute;
  top: -5px;
  left: 87px;
`
interface StationBoxProps {
  position?: number | undefined
  departureTime?: any
  order?: number
  coursecnt?: number
  isstudent?: number
}

type BusIconProps = {
  isoperation: number
  position?: string
  isLast: number
  departureTime: string
  arriveTime: string
}

const BusIconWrapper = styled.div<BusIconProps>`
  position: absolute;
  display: block;
  top: ${({isoperation, isLast, departureTime}) =>
    isoperation === 0 && departureTime === ''
      ? '13px'
      : isoperation === 0 && departureTime !== ''
        ? '80px'
        : isoperation === isLast
          ? '12px'
          : departureTime === ''
            ? '10px'
            : '55px'};
  width: 32px;
  height: 32px;
  left: ${({isoperation, isLast}) => (isoperation === isLast ? '74.5px' : '73px')};
  z-index: 99;
`

const StationBox = styled.div<StationBoxProps>`
  flex-basis: 80%;
  ${({order, coursecnt}) =>
    order === coursecnt
      ? css`
          border-left: none;
        `
      : order === 0
        ? css`
            border-left: none;
          `
        : order !== coursecnt
          ? css`
              border-left: 2px solid #999999;
              position: absolute;
              top: 0px;
            `
          : ''};
  padding: ${({isstudent}) => (isstudent === 1 ? '0 0 16px 30px' : '0 0 35px 30px')};
  position: relative;
  ::before {
    ${({order, coursecnt}) =>
      order === 0
        ? css`
            content: url(${DepartureStationIcon});
            margin-top: 10px;
            width: 50px;
          `
        : order === coursecnt
          ? css`
              content: url(${ArrivalStationIcon});
              margin-left: 2.5px;
              margin-top: 10px;
            `
          : css`
              content: url(${BusStopArrow});
              margin-top: 10px;
            `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 30px;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -11px;
    top: 10px;
    line-height: 1.1;
    background-color: #fff;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  ::after {
    content: '';
    position: absolute;
    display: ${({position}) => (position === 1 ? 'block' : 'none')};
    width: 30px;
    height: 30px;
    background: url(${BusIconBlue}) center center no-repeat;
    left: -16px;
    z-index: 1;
    top: -8px;
  }
  ${media.desktop} {
    flex-basis: 70%;
  }

  #empty {
    margin-left: 50px;
  }
`

interface StationLiProps {
  status?: number | undefined
  order?: number
  stationid?: number
  pickupstationid?: number
}

const Stationli = styled.li<StationLiProps>`
  display: flex;
  position: relative;
  background-color: ${({stationid, pickupstationid}) => (stationid === pickupstationid ? '#E9F4FF' : '')};
`

interface TimeBoxProps {
  arriveTime?: string | null | undefined
}

const TimeBox = styled.div<TimeBoxProps>`
  flex-basis: 66px;
  text-align: right;
  margin-right: 16px;
  min-width: 73px;
  padding: 16px;
  ${({arriveTime}) => arriveTime !== '' && css``}
`

const TimeGroup = styled.div`
  display: inline;
  width: 66px;
  text-align: left;
  min-height: 115px;
`

const EstimatedArrivalTime = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 36px;
`

export const StationName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 16px;
  color: #191919;
`

type StudentProps = {
  border?: string
}

const StudentWrapper = styled.div<StudentProps>`
  display: inline-block;
  margin-top: 8px;
  border-radius: 36px;
  border: ${({border}) => (border === 'true' ? '2px solid #2297FF' : '1px solid #d8d8d8')};
  background: #f9f9fa;
  padding: 6px 12px;
  align-items: center;
`
