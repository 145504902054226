import axios, { AxiosRequestConfig } from "axios";
import { AxiosEnum } from "enum/AxiosEnum";

export const setAccessToken = (token: string) => {
    sessionStorage.setItem("at", token);
};

export const getAccessToken = () => {
    sessionStorage.getItem("at");
};

export const token = sessionStorage.getItem("at");

export async function getToken() {
    const url = `https://dev.auth.yellowbus.services/v2/login`;

    const config: AxiosRequestConfig = {
        url: url,
        method: AxiosEnum.POST.value,
        data: {
            email: "kong@reverselab.co.kr",
            loginType: "ACADEMY",
            password: "1234",
        },
    };

    const axiosResponse = await axios(config);

    const result = axiosResponse.data;

    return result;
}
