import {CDateTime} from './Date'

export class CSchedule {
  private constructor(
    readonly arrivalStationId: number | null | undefined,
    readonly arrivalStationName: string,
    readonly boardSwitch: string,
    readonly busName: string,
    readonly departureStationId: number,
    readonly departureStationName: string,
    readonly dispatchCode: string,
    readonly endTime: CDateTime,
    readonly hasLogs: boolean,
    readonly scheduleDateTime: CDateTime,
    readonly scheduleId: number,
    readonly scheduleType: string,
    readonly startTime: CDateTime,
    readonly weekday: number,
    readonly operationBus: boolean,
    readonly startBus: boolean,
  ) {}

  static create(json: any): CSchedule {
    const scheduleDateTime = `${json.scheduleDate}T${json.scheduleTime}`
    const startDateTime = `${json.scheduleDate}T${json.startTime}`
    const endDateTime = `${json.scheduleDate}T${json.endTime}`
    return new CSchedule(
      json.arrivalStationId,
      json.arrivalStationName,
      json.boardSwitch,
      json.busName,
      json.departureStationId,
      json.departureStationName,
      json.dispatchCode,
      CDateTime.create(endDateTime),
      json.hasLogs,
      CDateTime.create(scheduleDateTime),
      json.scheduleId,
      json.scheduleType,
      CDateTime.create(startDateTime),
      json.weekday,
      json.operationBus,
      json.startBus,
    )
  }
}

export class CScheduleMap {
  private constructor(
    readonly date: CDateTime,
    readonly schedules: CSchedule[],
  ) {}

  static create(json: any) {
    return new CScheduleMap(
      CDateTime.create(json.date),
      json.schedule.map(el => CSchedule.create(el)),
    )
  }
}

export class CSearchSchedule {
  private constructor(
    readonly busName: string,
    readonly dispatchCode: string,
    readonly endTime: string,
    readonly startTime: string,
  ) {}

  static create(json: any): CSearchSchedule {
    return new CSearchSchedule(json.busName, json.dispatchCode, json.endTime, json.startTime)
  }
}
