import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'

// componenets
import Header from './Header'
import Summary from './Summary'
import Notification from './Notification'
import Course from './Course'
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import FullPopUp from 'components/commons/Modal'
import {getBusServiceDetailList} from 'service/BusService'
import CourseMap from 'pages/CourseMap'
import BottomNav from './BottomNav'

// model
import {getCourse} from 'service/Course'
import {CDateTime} from 'model/Date'

// styles
import {media} from 'styles/media'

// image
import MapIcon from 'assets/images/ic_map_icon.svg'
import RefreshIcon from 'assets/images/ic_refresh_icon.svg'
import WebRefreshIcon from 'assets/images/ic_web_refresh.svg'
import Loading from 'components/commons/Loading'
import {getBoarding} from 'service/Toggle'
import {Optional} from 'types/Common'

type NoticeProps = {
  noticeDate?: string
  message?: string
}

const BusRoute = () => {
  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(height, '*')
    }

    window.onresize = sendHeight
    setTimeout(() => {
      sendHeight()
    }, 500)
  })

  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const academyID = JSON.parse(localStorage.getItem('academyID'))
  const scheduleID =
    localStorage.getItem('scheduleID') === null || localStorage.getItem('scheduleID') === undefined
      ? location.state.scheduleID
      : JSON.parse(localStorage.getItem('scheduleID'))
  const dispatchCode =
    localStorage.getItem('dispatchCode') === null || localStorage.getItem('dispatchCode') === undefined
      ? location.state.dispatchCode
      : JSON.parse(localStorage.getItem('dispatchCode'))
  const today = CDateTime.create(new Date())
  const formatToday = today.getDate()

  const [busDetail, setBusDetail] = useState([])
  const [summary, setSummary] = useState(null)
  const [manager, setManager] = useState()
  const [courses, setCourses] = useState(undefined)
  const [notices, setNotices] = useState<NoticeProps[]>(undefined)
  const [isModal, setIsModal] = useState<boolean>(false)
  const [callTime, setCallTime] = useState(null)
  const [boardingSwitch, setBoardingSwitch] = useState<Optional<boolean>>(null)
  const recordTime = CDateTime.create(callTime)

  const getBusServiceDetail = useCallback(async () => {
    setLoading(true)
    await getBusServiceDetailList(academyID, dispatchCode, formatToday)
      .then(res => {
        setBusDetail(res)
      })
      .catch(error => error)
  }, [])

  const getToggleStatus = useCallback(async () => {
    await getBoarding(academyID, scheduleID)
      .then(res => {
        setBoardingSwitch(res.boardSwitch)
      })
      .catch(error => error)
  }, [])

  const getDispatchesDetail = async () => {
    await getCourse(scheduleID, dispatchCode, academyID)
      .then(res => {
        setSummary(res.scheduleStation)
        setManager(res.manager)
        setCourses(res.courses)
        setNotices(res.notices)
        const callEndTime = new Date()
        setCallTime(callEndTime)
      })
      .catch(error => error)
  }

  const handleRefreshButton = () => {
    getDispatchesDetail()
    getBusServiceDetail()
    getToggleStatus()
  }

  useEffect(() => {
    handleRefreshButton()
    let i = 0;
    let raf;
    const callback = function () {
        i++;
        if (i%3600 === 0){
            handleRefreshButton()
        }
        //console.log("22#!")
        if (i >= 36000000) {
            console.log('end')
            cancelAnimationFrame(raf);
            return;
        }
        raf = requestAnimationFrame(callback);
        //console.log(i++);
    }
    requestAnimationFrame(callback);
  }, []);
/*
  useEffect(() => {
    handleRefreshButton()

    setInterval(() => {
      handleRefreshButton()
    }, 10000)
  }, [])
*/



  const goToChangeStation = () => {
    navigate(`/modify/station`, {
      state: {
        scheduleID: scheduleID,
        dispatchCode: dispatchCode,
      },
    })
  }

  useEffect(() => {
    if (busDetail !== undefined && courses !== undefined) {
      setLoading(false)
    }
  }, [busDetail, courses])

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <LeftContainer>
        <Header summary={summary} />
        <Summary
          summary={summary}
          fetchCourse={getDispatchesDetail}
          scheduleID={scheduleID}
          boardingSwitch={boardingSwitch}
        />
        <Notification notices={notices} />
        <Course details={courses} busDetail={busDetail} summary={summary} />
        <BottomNav phone={manager} goToChangeStation={goToChangeStation} operation={summary?.operationBus} />
        <MapRefreshIcon>
          <MapImg onClick={() => setIsModal(true)}>
            <Img src={MapIcon} width={'100%'} height={'100%'} alt="map" />
          </MapImg>
          <Img onClick={handleRefreshButton} src={RefreshIcon} width={'60px'} alt="refresh" />
        </MapRefreshIcon>
      </LeftContainer>
      <RightContainer>
        <WebRefresh>
          <Text color="#708185" fontSize={12} fontWeight={500}>
            {recordTime.getFormatDateTime()}
          </Text>
          <ImgWrapper>
            <Img onClick={handleRefreshButton} src={WebRefreshIcon} alt="refresh" width={'24px'} />
          </ImgWrapper>
        </WebRefresh>

        <WebContainer>
          <ButtonWrapper>
            {summary?.operationBus ? '' : <Button onClick={() => goToChangeStation()}>정류장 변경</Button>}

            <Button onClick={() => setIsModal(true)}>지도 보기</Button>
          </ButtonWrapper>
          <NotiContainer>
            {notices?.length > 0 ? (
              notices?.map((item, idx) => {
                const timeFormat = CDateTime.create(item.noticeDate)
                return (
                  <NotiWrapper key={idx}>
                    <TimeWrapper>{`${timeFormat.getAmPm()} ${timeFormat.getTime()}`}</TimeWrapper>
                    <Text fontSize={13} lineHeight={15}>
                      {item.message}
                    </Text>
                  </NotiWrapper>
                )
              })
            ) : (
              <NotiWrapper>
                <Text fontSize={13} lineHeight={15}>
                  5분 전에 나와서 기다리는 센스!
                </Text>
              </NotiWrapper>
            )}
          </NotiContainer>
        </WebContainer>
      </RightContainer>
      {isModal && (
        <FullPopUp
          id={isModal}
          onClose={() => setIsModal(false)}
          height={'auto'}
          width={'auto'}
          contents={<CourseMap summary={summary} courses={courses} onClose={() => setIsModal(false)} />}
        />
      )}
    </Container>
  )
}

export default BusRoute

const Container = styled.div`
  background: #fff;
  padding-bottom: 60px;

  ${media.tablet} {
    height: 100vh;
    display: grid;
    grid-template-columns: calc(100vw - 365px) 340px;
    column-gap: 24px;
    padding: 23px 0px;
    background: #f7f8f9;
  }
`

const LeftContainer = styled.div`
  width: 100%;

  ${media.tablet} {
    background: var(--grayscale-gray-50, #f7f8f9);
  }
`

const RightContainer = styled.div`
  display: none;
  ${media.tablet} {
    width: 100%;
    display: inline-block;
  }
`
const MapRefreshIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 65px;
  right: 16px;
  z-index: 100;

  ${media.tablet} {
    display: none;
  }
`

const MapImg = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const WebRefresh = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 16px;
`

const ImgWrapper = styled.div`
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const Button = styled.button`
  width: 100%;
  height: 42px;
  border: 1px solid #2297ff;
  border-radius: 8px;
  background: #2297ff;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-family: Pretendard;
  margin-bottom: 16px;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
    cursor: pointer;
  }
`

const WebContainer = styled.div`
  width: 100%;
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

const NotiContainer = styled.div`
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  row-gap: 21px;

  ::-webkit-scrollbar {
    display: none; // chrome, safari, opera
  }
  scrollbar-width: none; // firefox

  -ms-overflow-style: none; // IE, edge

  :last-child {
    margin-bottom: 0px;
  }
`

const NotiWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`

const TimeWrapper = styled.div`
  min-width: 60px;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.3px;
`
