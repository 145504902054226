import {createGlobalStyle} from 'styled-components'
import {reset} from 'styled-reset'

const GlobalStyle = createGlobalStyle`
    ${reset}
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,  
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    /* wJk */
    *, *::before, *::after {
        box-sizing: border-box;
    }

    body {
				line-height: 1;
        color:#000;
        font-family:Pretendard, Helvetica, Arial, sans-serif;
        font-size:12px;
        background:#f7f7f7;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        scrollbar-width: none; /* Firefox */
				-ms-overflow-style: none; /* IE, Edge */
				overflow: -moz-scrollbars-none; /* Firefox */
				
				::-webkit-scrollbar {
 				  display: none; /* 웨일 브라우저 */
				}
    }
    
    body::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }

    a,
    a:visited,
    a:active{
        text-decoration:none;
        color:#000
    }

    input{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border:1px solid #999;
        height:36px
    }

    select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
            border:1px solid #999;
            border-radius:0;
            height:36px
    }

    label{
        margin-bottom:5px
    }

    button{
        width:100%;
        padding:10px
    }
    button:hover{
        cursor: pointer;
    }

    h1 {
        font-size: 28px;
        font-weight:800
    }

    h2 {
        font-size: 18px;
        font-weight:800
    }

    h3 {
        font-size: 16px;
        font-weight:800
    }

    h4 {
        font-size: 14px;
        font-weight:600
    }
`

export default GlobalStyle
