import React from 'react'
import {useNavigate} from 'react-router-dom'

const MESSAGE = 'message'

export function IFrame2() {
  const navigate = useNavigate()
  window.addEventListener(MESSAGE, handleMessage)

  function sendHeight() {
    const height = document.body.scrollHeight

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(height, '*')
  }

  window.onresize = sendHeight
  window.addEventListener('load', function () {
    setTimeout(() => {
      sendHeight()
    }, 500)
  })

  function handleMessage(e: any) {
    const receiveData = e.data

    localStorage.setItem('academyID', JSON.stringify(receiveData.academyId))
    localStorage.setItem('studentID', JSON.stringify(receiveData.studentId))
    localStorage.setItem('dispatchCode', JSON.stringify(receiveData.dispatchCode))
    localStorage.setItem('scheduleID', JSON.stringify(receiveData.scheduleId))
    localStorage.setItem('historyState', JSON.stringify(receiveData.historyState))

    if (receiveData.scheduleId !== undefined || receiveData.scheduleId !== undefined) {
      navigate('/info')
    }
  }

  return <div></div>
}
