import axios, {AxiosRequestConfig} from 'axios'
import {AxiosEnum} from 'enum/AxiosEnum'
import {API} from '../Env'

export async function patchScheduleStation(
  dispatchCode: string,
  scheduleType: string,
  pickUpStationID: number,
  scheduleID: number,
  takeOffStationID: number,
  academyID: string,
) {
  const url = `${API}/${academyID}/schedules/${scheduleID}/stations`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.PATCH.value,
    params: {
      academyId: academyID,
      scheduleId: scheduleID,
    },
    data: {
      dispatchCode: dispatchCode,
      stationId: scheduleType === '등원' ? pickUpStationID : takeOffStationID,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.code
}
