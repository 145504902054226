import React from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

// componenets
import Img from 'components/atoms/Image'
import Text from './Span'

// image
import BackButton from 'assets/images/ic_left_arrow.svg'
import CloseButton from 'assets/images/ic_close_icon.svg'
import {media} from 'styles/media'

const Header = props => {
  const navigate = useNavigate()

  const navigateBackPage = () => {
    if (props.route) {
      navigate(props.route)
    } else navigate(-1)
  }

  return (
    <Container>
      <ImgWrapper onClick={navigateBackPage}>
        <Img src={BackButton} width={'100%'} />
      </ImgWrapper>
      <Text fontSize={16} fontWeight={500} lineHeight={20} color="#191919" letterSpacing={-0.32}>
        {props.title}
      </Text>
      <CloseWrapper show={props.close} onClick={props.close}>
        <Img src={CloseButton} width={'100%'} />
      </CloseWrapper>
    </Container>
  )
}

export default Header

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 19px 16px;
  background: #fff;
  z-index: 999;

  ${media.tablet} {
    border-radius: 8px;
    justify-content: flex-start;
    padding-left: 52px;
    position: relative;
  }
`

const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 19px;
  left: 16px;
  cursor: pointer;
`

type CloseProps = {
  show: string
}

const CloseWrapper = styled.div<CloseProps>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 19px;
  right: 16px;
  display: ${({show}) => (show === 'true' ? 'display' : 'none')};
`
