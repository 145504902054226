import axios, {AxiosRequestConfig} from 'axios'
import {AxiosEnum} from 'enum/AxiosEnum'
import {CScheduleMap} from 'model/Schedule'
import {API} from '../Env'

export async function getSchedule(studentID: number, academyID: string) {
  const url = `${API}/${academyID}/schedules/week`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {studentId: studentID},
  }

  const axiosResponse = await axios(config)

  const arr = Object.entries(axiosResponse.data.data)
  const filteredArr = arr.map(el => {
    return {
      date: el[0],
      schedule: el[1],
    }
  })

  return filteredArr.map(el => CScheduleMap.create(el))
}

export async function getScheduleStation(scheduleID: number, academyID: string) {
  const url = `${API}/${academyID}/stations`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {scheduleId: scheduleID},
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getStationCarDispatching(
  stationID: string,
  scheduleID: string,
  academyID: string,
  searchDate: string,
) {
  const url = `${API}/${academyID}/dispatches/different-tickets`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {
      scheduleId: scheduleID,
      stationId: stationID,
      searchDate: searchDate,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function patchCarDispatching(
  dispatchCode: string,
  scheduleID: number,
  stationID: number,
  academyID: string,
  selectedDate: string,
) {
  const url = `${API}/${academyID}/schedules/${scheduleID}/stations`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.PATCH.value,
    // params: {
    //   scheduleId: scheduleID,
    // },
    data: {
      dispatchCode: dispatchCode,
      stationId: stationID,
      searchDate: selectedDate,
    },
  }

  const axiosResponse = await axios(config)

  return axiosResponse
}
