// @ts-ignore
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import L from 'leaflet'
import _ from 'lodash'

// components
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import {media} from 'styles/media'

// image
import RightArrow from 'assets/images/ic_right_gray_arrow.svg'
import Close from 'assets/images/ic_close_icon.svg'
import DepartureStationIcon from 'assets/images/ic_departure_station.svg'
import ArrivalIcon from 'assets/images/ic_arrival_icon.svg'
import ArrivalStationIcon from 'assets/images/ic_arrival_station.svg'
import StationIcon from 'assets/images/ic_station_icon.svg'
import {CDateTime} from 'model/Date'

import {TMapRoutesOptimization} from 'service/TMap'

type SummaryProps = {
  arrivalStationName: string
  departureStationName: string
  endTime: string
  scheduleDate: string
  scheduleId: number
  scheduleType: string
  startTime: string
}

type MapProps = {
  onClose?: () => void
  center?: any
  courses?: any
  attribution?: string
  summary?: SummaryProps
}

const CourseMap: React.FC<MapProps> = ({onClose, courses, summary}) => {
  const startDateTime = CDateTime.create(summary?.scheduleDate + 'T' + summary?.startTime)

  const [center, setCenter] = useState<any>({
    lat: courses[2].station.lat,
    lng: courses[2].station.lng,
  })
  const [bounds, setBounds] = useState()

  interface ViewportInterface {
    center: [number, number] | null | undefined
    zoom: number | null | undefined
  }

  useEffect(() => {
    setViewport(center)
  }, [center])

  const [viewport, setViewport] = useState<ViewportInterface>(center)

  const getRoutesOptimization = (station: any) => {
    const stationLength = station.length
    const endStation = station[stationLength - 1]

    let viaPoints

    if (station.length > 2) {
      // 출발과 도착 정류장을 제외하기 위한 slice
      viaPoints = station.slice(1, stationLength - 1).map((station: any, index: number) => ({
        viaPointId: `${index + 1}`,
        viaPointName: `stopover_${index + 1}`,
        viaX: `${station.station.lng}`,
        viaY: `${station.station.lat}`,
        viaTime: 0,
      }))
    } else {
      // 경유지가 없으면 경유지에 도착지 위경도 값을 넣어 경유지 생성
      // viaX와 viaY에 startStation 값을 넣으면 T-MAP API 에서 오류 발생함
      viaPoints = [
        {
          viaPointId: '1',
          viaPointName: 'stopover_1',
          viaX: `${endStation.station.lng}`,
          viaY: `${endStation.station.lat}`,
          viaTime: 0,
        },
      ]
    }
  }

  useEffect(() => {
    setViewport({
      center: [center?.lat, center?.lng],
      zoom: 15,
    })
  }, [center])

  useEffect(() => {
    if (courses?.length > 0) {
      getRoutesOptimization(courses)
    }
  }, [courses])

  const departureIndex = courses.findIndex((el: any) => el.station.name === summary.departureStationName)

  const arrivalIndex = courses.findIndex((el: any) => el.station.name === summary.arrivalStationName)

  return (
    <Container>
      <Header>
        <StationWrapper>
          <StationName>{summary?.departureStationName}</StationName>
          <ImgWrapper>
            <Img src={RightArrow} width={'100%'} alt="right" />
          </ImgWrapper>
          <StationName>{summary.arrivalStationName}</StationName>
        </StationWrapper>
        <CloseImg onClick={() => onClose()}>
          <Img src={Close} width={'100%'} alt="close" />
        </CloseImg>
      </Header>

      <div className="map_container">
        <MapContainer
          // @ts-ignore
          center={[center.lat, center.lng]}
          zoom={16}
          bounds={bounds}
          className="map">
          <TileLayer
            // @ts-ignore
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {courses?.map((station: any, idx: number) => {
            const lat: number = parseFloat(station?.station?.lat)
            const lng: number = parseFloat(station?.station?.lng)
            const stationLength = courses.length
            const stationId = Number(station?.station.id)
            return (
              <Marker
                key={stationId}
                position={[lat, lng]}
                // @ts-ignore
                icon={getStationIcon(idx, station, stationLength, departureIndex, arrivalIndex)}>
                <Popup>{station?.station.name}</Popup>
              </Marker>
            )
          })}
        </MapContainer>
      </div>
      <BottomInfo>
        <div style={{height: '58px'}}>
          <Text color={'#2297FF'} fontSize={18} fontWeight={500} lineHeight={21} letterSpacing={-0.36}>
            {summary?.departureStationName}
          </Text>
          <TimeWrapper>
            <Text color={'#fff'} fontSize={12} lineHeight={14} fontWeight={500} letterSpacing={-0.24}>
              {`${startDateTime.getTime()} ${startDateTime.getAMPM()}`}
            </Text>
            <Text fontSize={12} fontWeight={500} lineHeight={14} color={'#2297FF'} letterSpacing={-0.24}>
              {`[승차 예정]`}
            </Text>
          </TimeWrapper>
        </div>
      </BottomInfo>
    </Container>
  )
}

export default CourseMap

const BottomInfo = styled.div`
  bottom: 0%;
  position: absolute;
  z-index: 999999;
  width: 100%;
  padding: 20px 22px;
  border-radius: 20px 20px 0px 0px;
  background: rgba(0, 0, 0, 0.7);

  ${media.tablet} {
    bottom: 0px;
    border-radius: 20px 20px 7px 7px;
  }
`

const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  column-gap: 7px;
`

const Container = styled.div`
  position: relative;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none; // chrome, safari, opera
  }
  scrollbar-width: none; // firefox

  -ms-overflow-style: none; // IE, edge

  .map_container {
    height: 100vh;

    ${media.tablet} {
      height: 576px;
      width: 472px;
      ::-webkit-scrollbar {
        display: none; // chrome, safari, opera
      }
      scrollbar-width: none; // firefox

      -ms-overflow-style: none; // IE, edge
    }
  }
  .map {
    height: 100%;

    ${media.tablet} {
      height: 576px;
    }
  }
  .leaflet-container .leaflet-control-attribution {
    display: none;
  }
  .leaflet-top {
    margin-top: 45%;
  }
  .leaflet-left {
    margin-left: 87%;

    ${media.fold} {
      margin-left: 80%;
    }
    ${media.tablet} {
      margin-left: 88%;
    }
  }
  .leaflet-touch .leaflet-bar a:first-child {
    width: 33px;
    height: 33px;
    border-radius: 6px 6px 0px 0px;
    color: #585858;
  }
  .leaflet-touch .leaflet-bar a:last-child {
    width: 33px;
    height: 33px;
    border-radius: 0px 0px 6px 6px;
    color: #585858;
  }
  .leaflet-touch .leaflet-bar {
    border: 2px solid rgba(0 0, 0, 0.2);
    border-radius: 6px;
  }
`

const getStationIcon = (
  index: number,
  station: any,
  stationLength: number,
  departureIndex: number,
  arrivalIndex: number,
) => {
  const icon = L.divIcon({
    className: 'station-icon',
    html: `<div className="index" style="
                width: 30px;
                background: url(${
                  departureIndex === index ? DepartureStationIcon : arrivalIndex === index ? ArrivalIcon : StationIcon
                }) center center no-repeat;
                background-size:contain;
                width: ${departureIndex === index ? '40px' : arrivalIndex === index ? '50px' : '26px'};
                height: ${departureIndex === index ? '40px' : arrivalIndex === index ? '36px' : '29px'};
                display:inline-flex;
                align-items:center;
                justify-content:center;
                transform:translate3d(calc(-50% + 6px),calc(-100% + 12px),0);
                position:relative;">
                <div style="color:white;font-size:13px;line-height:18px;font-weight:800;position: absolute; top: 59%; left: 50%; transform: translate(calc(-50%), calc(-50% - 3px));">${
                  departureIndex === index || arrivalIndex === index ? '' : index
                }</div><div className="name" style="display:none;width:100px;padding:10px;margin-left:-50px;background:pink">${
                  station?.station.name
                }</div></div>`,
  })
  return icon
}

const Header = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #fff;
  justify-content: space-between;
  padding: 18px 16px;
  border-bottom: 1px solid #ebebed;
  z-index: 99999999;

  ${media.tablet} {
    position: sticky;
  }
`

const StationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const StationName = styled.div`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.32px;
  width: 45%;
  font-weight: 500;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${media.tablet} {
    width: 189px;
  }
`

const ImgWrapper = styled.div`
  margin: 0px 10px;
  width: 12px;
  height: 12px;
  ${media.tablet} {
    margin: 0px 15px;
  }
`

const CloseImg = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
