import React, { useContext, useEffect, useRef } from "react";
import axios from "axios";
import jwt from "jwt-decode";
import { getToken, token, setAccessToken } from "service/token";

// utils
import Router from "./Router";

// context
import { getAccessToken } from "service/token";

const App = () => {
    document.title = `최선어학원`;

    return (
        <>
            {/* <AxiosTokenInterceptor /> */}
            {/* <AxiosSpinnerInterceptor /> */}
            <Router />
        </>
    );
};

export default App;

const AxiosTokenInterceptor = () => {
    const isLogoutAlert = useRef(false);

    const accessToken = getAccessToken();
    useEffect(() => {
        getToken().then((res) => {
            if (res.code === "OK") {
                setAccessToken(res.data.accessToken);
            }
        });
        axios.interceptors.request.use(
            async (config) => {
                console.log(config);
                config.headers.Authorization = `Bearer ${accessToken} `;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }, []);
    return null;
};

// const tokenExpireCheck = (token) => {
//     console.log(token);

// };

// const AxiosSpinnerInterceptor = () => {
// const { setLoading } = useContext(SpinnerContext);

// useEffect(() => {
// Axios Request Interceptor
// axios.interceptors.request.use(
//     (config) => {
//         console.log(config);
//         return config;
//     },
//     function (error) {
//         return Promise.reject(error);
//     }
// );

// Axios Response Interceptor
// axios.interceptors.response.use(
//     function (response) {
//         setTimeout(function () {
//             // setLoading(false);
//         }, 100);

//         return response;
//     },
//     function (error) {
//         setTimeout(function () {
//             // setLoading(false);
//         }, 100);
//         return Promise.reject(error);
//     }
// );
// eslint-disable-next-line
// }, []);

// return null;
// };
