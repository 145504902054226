import {DayEnum} from 'enum/DayEnum'
import moment from 'moment'

export class CDateTime {
  private readonly value: moment.Moment

  constructor(input: moment.Moment) {
    this.value = input
  }

  static create(input: moment.MomentInput) {
    const m = moment(input)
    // validation check

    return new CDateTime(m)
  }

  static now(): number {
    return moment.now()
  }

  getValue(): moment.Moment {
    return this.value
  }

  getDate(): string {
    return this.value.startOf('day').format('YYYY-MM-DD')
  }

  getMonth(): number {
    const monthInt = Number(this.value.month())

    return monthInt + 1
  }

  getDay(): number {
    return this.value.date()
  }

  getDayOfTheWeek(): DayEnum {
    const day = this.value.format('dd')
    const parsedDay = DayEnum.parse(day)

    return parsedDay
  }

  toDate(): CDate {
    return CDate.create(this.value)
  }

  getTime(): string {
    return this.value.format('hh:mm')
  }

  getTime24(): string {
    return this.value.format('HH:mm')
  }

  getAmPm(): string {
    return this.value.format('A') === 'AM' ? '오전' : '오후'
  }

  getAMPM(): string {
    return this.value.format('A')
  }

  getDateTime(): string {
    return this.value.startOf('day').format('YYYY-MM-DD') + 'T' + this.value.format('hh:mm:ss')
  }

  getFormatDateTime(): string {
    return this.value.format('YYYY.MM.DD.') + ' ' + this.value.format('HH:mm:ss')
  }

  getFormatText(): string {
    return (
      this.value.format('YYYY') +
      '년 ' +
      this.value.format('MM') +
      '월 ' +
      this.value.format('DD') +
      '일 ' +
      '(' +
      DayEnum.parse(this.value.format('dd')) +
      ')'
    )
  }

  isBefore(time: moment.Moment): boolean {
    return this.value.isBefore(time)
  }

  isAfter(time: moment.Moment): boolean {
    return this.value.isAfter(time)
  }
}

export class CDate {
  static readonly FORMAT_DEFAULT = 'YYYY-MM-DD'
  private value: moment.Moment

  private constructor(input: moment.Moment) {
    this.value = moment(input).startOf('day')
  }

  static create(input: moment.MomentInput) {
    const m = moment(input)
    if (!m.isValid()) {
      throw new Error(`invalid input. (input: ${input})`)
    }

    return new CDate(m)
  }

  static now() {
    return CDate.create(moment.now())
  }

  add(arg0: number, arg1: string) {
    throw new Error('Method not implemented.')
  }

  isValid(): boolean {
    return this.value.isValid()
  }

  equals(input: CDate): boolean {
    return this.value.isSame(input.value)
  }

  isBefore(input: CDate): boolean {
    return this.value.isBefore(input.value)
  }

  isAfter(input: CDate): boolean {
    return this.value.isAfter(input.value)
  }

  toString(): string {
    return this.value.format(CDate.FORMAT_DEFAULT)
  }

  toDateRaw(): Date {
    return new Date(this.value.toISOString())
  }
}
