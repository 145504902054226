import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Station from './Station'

// components
import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'
import {media} from 'styles/media'

// image
import CourseFirstPin from 'assets/images/ic_course_start_pin.svg'
import CourseFirstLongPin from 'assets/images/ic_course_start_long_pin.svg'

const Course = (props: any) => {
  const {details, busDetail, summary} = props
  const [firstStudent, setFirstStudent] = useState<number>(0)

  useEffect(() => {
    if (details?.length > 0) {
      setFirstStudent(details[0].station.students.length)
    }
  }, [details])

  const busIdx = busDetail.findIndex(el => el.position === 'EXISTS')

  return (
    <Container>
      <TitleWrapper>
        <Text color="#708185" fontSize={12}>
          예정 시간
        </Text>
        <Text color="#708185" fontSize={12}>
          정류장
        </Text>
      </TitleWrapper>

      <Wrapper>
        {details?.length > 0 && (
          <ImgWrapper>
            <Img src={firstStudent === 1 ? CourseFirstLongPin : CourseFirstPin} width={'6px'} alt="firstpin" />
          </ImgWrapper>
        )}

        {details?.map((detail: any, idx: number) => {
          return (
            <Station
              key={`detail-${idx}`}
              order={idx}
              detail={detail}
              isLast={details.length - 1}
              detailsCnt={details.length}
              existIdx={busIdx}
              busDetail={busDetail}
              summary={summary}
            />
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default Course

const Container = styled.div`
  background: #fff;
  padding: 18px 0px;

  ${media.tablet} {
    border-radius: 8px;
    margin-top: 16px;
    height: calc(100vh - 230px);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  column-gap: 61px;
  padding: 0 16px;
`

const Wrapper = styled.ul`
  width: 100%;
  position: relative;
  background: #fff;
`

export const StationName = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #191919;
`

const ImgWrapper = styled.div`
  z-index: 11;
  position: absolute;
  top: 57px;
  left: 87px;
`
