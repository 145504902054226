import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'

import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'
import FullPopUp from 'components/commons/Modal'
import {media} from 'styles/media'
import SearchStation from './SearchStation'
import SubmitModal from './SubmitModal'
import CourseStation from './CourseStation'

import {getCourse} from 'service/Course'
import {CScheduleStation} from '../../model/ScheduleStation'
import {getStationCarDispatching, patchCarDispatching} from 'service/Schedule'
import {CSearchSchedule} from 'model/Schedule'
import HalfPopUp from 'components/commons/HalfPopUp'
import '../../components/commons/DatePicker/Datepicker.css'
import {CDateTime} from 'model/Date'
import SingleDatePicker from 'components/commons/DatePicker/DatePicker'
import Loading from 'components/commons/Loading'

// image
import DownArrow from 'assets/images/ic_down_line_arrow.svg'
import RightArrow from 'assets/images/ic_right_gray_arrow.svg'
import BackButton from 'assets/images/ic_left_arrow.svg'
import Close from 'assets/images/ic_close_icon.svg'
import CloseButton from 'assets/images/ic_close_icon.svg'
import Warning from 'assets/images/ic_warning_icon.svg'
import BusIcon from 'assets/images/ic_bus_gray.svg'

type SelectionProps = {
  id: number
  name: string
  lat: number
  lng: number
}

export const timeFormat = (time: string) => {
  if (Number(time.slice(0, 2)) > 12) {
    if (Number(time.slice(0, 2)) - 12 < 10) {
      return '0' + (Number(time.slice(0, 2)) - 12) + time.slice(2, 5)
    }
    if (Number(time.slice(0, 2)) - 12 > 9) {
      return Number(time.slice(0, 2)) + time.slice(2, 5)
    }
  }
  if (Number(time.slice(0, 2)) < 12 || Number(time.slice(0, 2)) === 12) {
    return time.slice(0, 5)
  }
}

function ChangeSchedule() {
  useEffect(() => {
    function sendHeight() {
      const height = document.body.scrollHeight

      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(height, '*')
    }

    window.onresize = sendHeight

    setTimeout(() => {
      sendHeight()
    }, 500)
  })

  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const scheduleID =
    localStorage.getItem('scheduleID') === null || localStorage.getItem('scheduleID') === undefined
      ? location.state.scheduleID
      : JSON.parse(localStorage.getItem('scheduleID'))
  const dispatchCode =
    localStorage.getItem('dispatchCode') === null || localStorage.getItem('dispatchCode') === undefined
      ? location.state.dispatchCode
      : JSON.parse(localStorage.getItem('dispatchCode'))
  const academyID = JSON.parse(localStorage.getItem('academyID'))

  const [stationData, setStationData] = useState<CScheduleStation>(null)
  const [courses, setCourses] = useState([])
  const [firstStudent, setFirstStudent] = useState<number>(0)
  const [selectedStation, setSelectedStation] = useState<SelectionProps>({
    id: 0,
    name: '',
    lat: 0,
    lng: 0,
  })

  const [isShowTickets, setIsShowTickets] = useState<boolean>(false)
  const [isApplyModal, setIsApplyModal] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [searchList, setSearchList] = useState<CSearchSchedule[]>([])
  const [isModal, setIsModal] = useState<boolean>(false)
  const [isCourseModal, setIsCourseModal] = useState<boolean>(false)
  const [selectedIdx, setSelectedIdx] = useState<number>(null)
  const [changeStationName, setChangeStationName] = useState<string>('')
  const [selectedStationID, setSelectedStationID] = useState<string>(undefined)
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [currentDate, setCurrentDate] = useState<string>('')
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false)
  const [stationEqual, setStationEqual] = useState<boolean>(false)
  const [submitData, setSubmitData] = useState<CSearchSchedule>({
    startTime: '',
    endTime: '',
    dispatchCode: '',
    busName: '',
  })
  const [submitStation, setSubmitStation] = useState({
    departureStation: '',
    arrivalStation: '',
  })
  const [selectedInfo, setSelectedInfo] = useState<CSearchSchedule>({
    startTime: '',
    endTime: '',
    dispatchCode: '',
    busName: '',
  })

  const handleSelectStation = useCallback((stationID: number, scheduleId: string, stationName: string) => {
    setIsModal(false)
    setSelectedStationID(stationID.toString())
    setChangeStationName(stationName.toString())
    setIsShowTickets(false)
  }, [])

  useEffect(() => {
    if (stationData?.scheduleType === '등원') {
      setSubmitStation({
        departureStation: changeStationName,
        arrivalStation: stationData.arrivalStationName,
      })
    }
    if (stationData?.scheduleType === '하원') {
      setSubmitStation({
        departureStation: stationData.departureStationName,
        arrivalStation: changeStationName,
      })
    }
  }, [changeStationName, stationData?.scheduleType])

  const getDispatchDetail = useCallback(async () => {
    await getCourse(scheduleID, dispatchCode, academyID)
      .then(res => {
        setStationData(res.scheduleStation)
        setCourses(res.courses)
      })
      .catch(error => error)
  }, [])

  useEffect(() => {
    // lat,lng 구하기위한 임시코드
    const stationLatLng = courses.filter((item: any) =>
      stationData?.scheduleType === '등원'
        ? item.station.name === stationData?.departureStationName
        : item.station.name === stationData?.departureStationName,
    )
    setSelectedStation(
      stationData?.scheduleType === '등원'
        ? {
            id: stationData?.departureStationId,
            name: stationData?.departureStationName,
            lat: stationLatLng[0]?.station.lat,
            lng: stationLatLng[0]?.station.lng,
          }
        : {
            id: stationData?.arrivalStationId,
            name: stationData?.arrivalStationName,
            lat: stationLatLng[0]?.station.lat,
            lng: stationLatLng[0]?.station.lng,
          },
    )
  }, [stationData, courses])

  useEffect(() => {
    if (stationData !== null) {
      setSelectedDate(stationData.scheduleDate)
      setCurrentDate(stationData.scheduleDate)
    }
  }, [stationData])

  useEffect(() => {
    // if (scheduleID === undefined || dispatchCode === undefined) {
    //   setLoading(true)
    // }
    // if (scheduleID !== undefined && dispatchCode !== undefined) {
    getDispatchDetail()
    //   setLoading(false)
    // }
  }, [scheduleID, dispatchCode, academyID])

  useEffect(() => {
    if (stationData !== null && stationData.scheduleType === '등원') {
      setSelectedStationID(`${stationData.departureStationId}`)
    }
    if (stationData !== null && stationData.scheduleType === '하원') {
      setSelectedStationID(`${stationData.arrivalStationId}`)
    }
  }, [stationData])

  useEffect(() => {
    if (courses?.length > 0) {
      setFirstStudent(courses[0].station.studenets?.length)
    }
  }, [courses])

  const goToStationMap = () => {
    setIsModal(true)
  }

  const handleSelectedTicket = (ticketIdx: number) => {
    setSelectedIdx(ticketIdx)
    setSubmitData(searchList[ticketIdx])
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickTicket = (ticketInfo: any) => {
    setSelectedInfo({
      startTime: ticketInfo.startTime,
      endTime: ticketInfo.endTime,
      dispatchCode: ticketInfo.dispatchCode,
      busName: ticketInfo.busName,
    })
    setIsCourseModal(true)
  }

  const putChangeSchedule = useCallback(async () => {
    await patchCarDispatching(submitData.dispatchCode, scheduleID, Number(selectedStationID), academyID, selectedDate)
      .then(res => {
        setIsApplyModal(false)
        setConfirm(true)
      })
      .catch(error => error)
  }, [submitData.dispatchCode, scheduleID, selectedStationID])

  const dateChange = useCallback((date: Date) => {
    const selectedDate = CDateTime.create(date)
    const formatDate = selectedDate.getDate()
    setSelectedDate(formatDate)
  }, [])

  const handleSearch = useCallback(
    (selectedStationID: string, scheduleID: string) => {
      getStationCarDispatching(selectedStationID, scheduleID, academyID, selectedDate)
        .then(res => {
          setSearchList(res)
          setSelectedIdx(null)
          setIsShowTickets(true)
        })
        .catch(error => error)
    },
    [selectedDate, searchList],
  )

  useEffect(() => {
    if (selectedDate !== currentDate) {
      setIsShowTickets(false)
    }
  }, [selectedDate, currentDate])

  useEffect(() => {
    if (
      (stationData?.scheduleType === '하원' && stationData?.departureStationName === changeStationName) ||
      (stationData?.scheduleType === '등원' && stationData?.arrivalStationName === changeStationName)
    ) {
      setDisabledSearch(true)
      setStationEqual(true)
    }

    if (
      (stationData?.scheduleType === '하원' && stationData?.departureStationName !== changeStationName) ||
      (stationData?.scheduleType === '등원' && stationData?.arrivalStationName !== changeStationName)
    ) {
      setStationEqual(false)
    }
  }, [
    changeStationName,
    stationData?.arrivalStationName,
    stationData?.departureStationName,
    stationData?.scheduleType,
    stationEqual,
  ])

  const historyState = localStorage.getItem('historyState')

  const historyStateBack = () => {
    window.parent.postMessage({type: 'navigateBack', payload: '/'}, '*')
    localStorage.removeItem('historyState')
  }

  return (
    stationData !== null &&
    selectedStation.id !== 0 && (
      <Container>
        <Header>
          <BackImg
            onClick={
              isShowTickets
                ? () => setIsShowTickets(false)
                : historyState !== null
                  ? historyStateBack
                  : () => navigate('/schedules')
            }>
            <Img src={BackButton} width={'100%'} alt="close" />
          </BackImg>
          <Text fontSize={16} lineHeight={20} letterSpacing={-0.32} fontWeight={500}>
            {!isShowTickets ? '승차권 검색' : '승차권 선택'}
          </Text>
          {isShowTickets && (
            <CloseImg onClick={() => navigate(-1)}>
              <Img src={Close} width={'100%'} alt="close" />
            </CloseImg>
          )}
        </Header>
        <PaddingWrapper>
          <StationWrapper>
            <Text color={'#708185'} fontSize={14} fontWeight={700} letterSpacing={-0.28}>
              {!isShowTickets ? '승차 또는 하차하실 정류장과 일자를 선택해 주세요.' : '이용하실 노선을 선택해 주세요.'}
            </Text>
            <StationInfo>
              <TimeDateWrapper>
                <TimeWrapper>
                  <Text fontSize={14} fontWeight={500} letterSpacing={-0.28} lineHeight={43}>
                    승차
                  </Text>
                  <ImgWrapper>
                    <Img src={DownArrow} width={'100%'} />
                  </ImgWrapper>
                  <Text fontSize={14} fontWeight={500} letterSpacing={-0.28} lineHeight={43}>
                    하차
                  </Text>
                </TimeWrapper>

                <DateText>일자</DateText>
              </TimeDateWrapper>
              <StationNameWrapper>
                <DepartureBorderBox
                  isShowTickets={isShowTickets}
                  type={stationData?.scheduleType}
                  onClick={stationData.scheduleType === '등원' ? () => goToStationMap() : () => {}}>
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={stationData.scheduleType === '등원' ? '#191919' : '#999999'}>
                    {stationData.scheduleType === '등원' && changeStationName === ''
                      ? selectedStation.name
                      : stationData.scheduleType === '등원' && changeStationName !== ''
                        ? changeStationName
                        : stationData.departureStationName}
                  </Text>
                </DepartureBorderBox>
                <ArrivalBorderBox
                  isShowTickets={isShowTickets}
                  type={stationData.scheduleType}
                  onClick={stationData.scheduleType === '하원' ? () => goToStationMap() : () => {}}>
                  <Text
                    fontSize={16}
                    fontWeight={500}
                    color={stationData.scheduleType === '등원' ? '#999999' : '#191919'}>
                    {stationData.scheduleType === '하원' && changeStationName === ''
                      ? selectedStation.name
                      : stationData.scheduleType === '하원' && changeStationName !== ''
                        ? changeStationName
                        : stationData.arrivalStationName}
                  </Text>
                </ArrivalBorderBox>
                <SingleDatePicker date={new Date()} dateChange={dateChange} selectedDate={selectedDate} />
                <SearchButton
                  disabled={stationEqual}
                  onClick={
                    stationEqual
                      ? () => {}
                      : () => {
                          handleSearch(selectedStationID, scheduleID)
                          setCurrentDate(selectedDate)
                        }
                  }>
                  검색
                </SearchButton>
              </StationNameWrapper>
            </StationInfo>
          </StationWrapper>
        </PaddingWrapper>
        {isModal && (
          <FullPopUp
            id={isModal}
            onClose={() => setIsModal(false)}
            contents={
              <SearchStation
                onClose={() => setIsModal(false)}
                handleSelectStation={handleSelectStation}
                stationData={stationData}
                scheduleID={scheduleID}
                station={selectedStation}
              />
            }
          />
        )}
        {isCourseModal && (
          <FullPopUp
            id={isCourseModal}
            onClose={() => setIsCourseModal(false)}
            contents={
              <CourseStation
                stationData={stationData}
                selectedInfo={selectedInfo}
                onClose={() => setIsCourseModal(false)}
              />
            }
          />
        )}

        {isShowTickets && searchList.length === 0 && (
          <EmptyContainer>
            <WarningImg>
              <Img src={Warning} width={'100%'} alt="warning" />
            </WarningImg>
            <Text color="#708185" fontSize={16} fontWeight={500} lineHeight={22}>
              현재 이용 가능한 승차권이 없습니다.
            </Text>
          </EmptyContainer>
        )}
        {isShowTickets && searchList.length > 0 && (
          <TicketContainer idx={selectedIdx}>
            {searchList.map((item: CSearchSchedule, idx: number) => {
              return (
                <Ticket key={`key - ${idx}`} checked={selectedIdx} idx={idx}>
                  <TicketInfo onClick={() => handleSelectedTicket(idx)}>
                    <TimeInfo>
                      <TextWrapper>
                        <Text fontSize={18} fontWeight={500} lineHeight={24} letterSpacing={-0.36}>
                          {Number(item.startTime.slice(0, 2)) < 12 ? '오전' : '오후'}
                        </Text>
                        <Text fontSize={24} fontWeight={500} lineHeight={30}>
                          {timeFormat(item.startTime)}
                        </Text>
                      </TextWrapper>
                      <ImgWrapper style={{margin: '0px 10px'}}>
                        <Img src={RightArrow} width={'100%'} alt="right" />
                      </ImgWrapper>
                      <TextWrapper>
                        <Text fontSize={18} fontWeight={500} lineHeight={24} letterSpacing={-0.36}>
                          {Number(item.endTime.slice(0, 2)) < 12 ? '오전' : '오후'}
                        </Text>
                        <Text fontSize={24} fontWeight={500} lineHeight={30}>
                          {timeFormat(item.endTime)}
                        </Text>
                      </TextWrapper>
                    </TimeInfo>
                    <Text fontSize={12} fontWeight={400} lineHeight={20} color="#708185">
                      {item.busName}
                    </Text>
                  </TicketInfo>
                  <CheckedTicket onClick={() => handleClickTicket(item)}>
                    <CheckImgWrapper>
                      <Img src={BusIcon} width={'100%'} alt="checked" />
                    </CheckImgWrapper>
                  </CheckedTicket>
                </Ticket>
              )
            })}
          </TicketContainer>
        )}

        {isApplyModal && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            top="195px"
            left="220px"
            width="600px"
            id={isApplyModal}
            onClose={() => setIsApplyModal(false)}
            contents={
              <SubmitModal
                stationData={stationData}
                submitStation={submitStation}
                selectedDate={selectedDate}
                selectedStation={selectedStation.name}
                data={submitData}
                onClose={() => setIsApplyModal(false)}
                putChangeSchedule={putChangeSchedule}
              />
            }
          />
        )}

        {confirm && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            width="400px"
            top="25%"
            left="25%"
            id={confirm}
            onClose={() => setConfirm(false)}
            contents={
              <ConfirmModal
                onClose={() => {
                  setConfirm(false)
                  navigate(-1)
                }}
              />
            }
          />
        )}

        {disabledSearch && (
          <HalfPopUp
            mobileWidth={'calc(100% - 32px)'}
            width="400px"
            top="25%"
            left="25%"
            id={disabledSearch}
            onClose={() => setDisabledSearch(false)}
            contents={
              <DisabledModal
                onClose={() => {
                  setDisabledSearch(false)
                }}
              />
            }
          />
        )}

        {selectedIdx !== null && (
          <ButtonWrapper>
            <SelectedTicketButton onClick={() => setIsApplyModal(true)}>선택 확인하기</SelectedTicketButton>
          </ButtonWrapper>
        )}
      </Container>
    )
  )
}

export default ChangeSchedule

type ModalProps = {
  onClose?: React.MouseEventHandler<HTMLButtonElement>
}

const ConfirmModal = (props: ModalProps) => {
  const {onClose} = props
  return (
    <ModalContainer>
      <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
        일일 스케줄 변경이 완료되었습니다.
      </Text>
      <Button onClick={onClose}>확인</Button>
      <CloseConfirmImg onClick={() => onClose}>
        <Img src={CloseButton} width={'100%'} alt="close" />
      </CloseConfirmImg>
    </ModalContainer>
  )
}

const DisabledModal = (props: ModalProps) => {
  return (
    <ModalContainer style={{rowGap: '10px'}}>
      <Text fontSize={18} fontWeight={500} lineHeight={20} letterSpacing={-0.4}>
        승차와 하차가 같은 정류장은
      </Text>
      <Text fontSize={18} fontWeight={500} lineHeight={20} letterSpacing={-0.4}>
        선택할 수 없습니다.
      </Text>
      <Button onClick={props.onClose} style={{marginTop: '30px'}}>
        확인
      </Button>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  padding: 60px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 60px;
  position: relative;
`

const Button = styled.button`
  border: 1px solid #e9f4ff;
  height: 48px;
  font-weight: 700;
  background: #2297ff;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  font-family: Pretendard;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
    cursor: pointer;
  }
`

const CloseImg = styled.div`
  position: absolute;
  top: 19px;
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${media.tablet} {
    display: none;
  }
`

const BackImg = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 19px;
  left: 16px;
  cursor: pointer;
`

const DatePickerImg = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
`

const CloseConfirmImg = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 19px;
    right: 16px;
    cursor: pointer;
  }
`

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px auto;
  height: 142px;
  /* margin-top: 336px; */
  background: #fff;

  ${media.tablet} {
    /* height: 222px; */
    /* margin-top: 366px; */
    padding: 100px auto;
  }
`

const WarningImg = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`

type ButtonProps = {
  idx: null | number
}
const TicketContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 16px 80px 16px;
  row-gap: 12px;
  margin-top: 17px;
  margin-bottom: ${props => (props.idx !== null ? '68px' : '0px')};
  /* max-height: ${props => (props.idx !== null ? 'calc(100vh - 72px)' : '100%')}; */
  /* overflow: scroll; */

  ::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }

  ${media.tablet} {
    background: #fff;
    border-radius: 8px;
    /* max-height: ${props => (props.idx !== null ? 'calc(100vh - 350px)' : '100%')}; */
    /* overflow-y: scroll; */
    /* margin-top: 366px; */

    ::-webkit-scrollbar {
      display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
  }
`

type TicketProps = {
  checked: number
  idx: number
}

const Ticket = styled.div<TicketProps>`
  display: grid;
  grid-template-columns: 1fr 56px;
  border-radius: 8px;
  border: ${props => (props.checked === props.idx ? '3px solid #2297ff' : '1px solid #e6e6e6')};
  background: #fff;
  transition: all 0.2s;

  &:hover {
    cursor: pointer;
    transition: 0.3s all linear;
    background: #f7f7f7;
  }
`

const TicketInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-right: 1px solid #e6e6e6;
  row-gap: 2px;
`

const TextWrapper = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: center;
  align-items: flex-end;
`

const TimeInfo = styled.div`
  display: flex;
  align-items: center;
`

const CheckedTicket = styled.div`
  padding: 30px 16px;
`

const CheckImgWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  position: fixed;
  background: #fff;
  bottom: 0px;
  padding-top: 16px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`

const SelectedTicketButton = styled.button`
  width: calc(100% - 32px);
  min-height: 48px;
  max-height: 48px;
  background: #2297ff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #e9f4ff;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  font-weight: 700;
  font-family: Pretendard;
`

const Container = styled.div`
  background: #fff;
  height: 100vh;
  position: relative;

  ${media.tablet} {
    background: #f7f8f9;
    padding: 23px 0px 0px;
  }
`

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 19px 16px;
  border-bottom: 1px solid #ebebed;
  width: 100%;
  display: block;
  justify-content: center;
  background: #fff;
  text-align: center;
  z-index: 99;

  ${media.tablet} {
    border-radius: 8px;
    border-bottom: 16px solid #f7f8f9;
    justify-content: flex-start;
    padding-left: 52px;
    top: 23px;
    text-align: left;
    z-index: 99;
  }
`

const PaddingWrapper = styled.div`
  padding-top: 59px;

  ${media.tablet} {
    padding-top: 75px;
  }
`

const StationWrapper = styled.div`
  width: 100%;
  background: #f7f7f7;
  padding: 16px;
  overflow: scroll;
  border-bottom: 1px solid #ebebed;

  ::-webkit-scrollbar {
    display: none; // chrome, safari, opera
  }
  scrollbar-width: none; // firefox

  -ms-overflow-style: none; // IE, edge

  ${media.tablet} {
    background: #fff;
    padding-top: 16px;
    border-bottom: none;
    border-radius: 8px;

    ::-webkit-scrollbar {
      display: none; // chrome, safari, opera
    }
    scrollbar-width: none; // firefox

    -ms-overflow-style: none; // IE, edge
  }
`

const StationInfo = styled.div`
  display: grid;
  grid-template-columns: 30px calc(100% - 48px);
  column-gap: 16px;
  margin-top: 16px;
  /* height: 154px; */
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const StationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const ImgWrapper = styled.div`
  width: 12px;
  height: 12px;
`

type BorderBoxProps = {
  type: string
  isShowTickets: boolean
}

const DepartureBorderBox = styled.div<BorderBoxProps>`
  width: 100%;
  height: 46px;
  padding: 12px;
  border-radius: 8px;
  /* border: ${({type, isShowTickets}) =>
    isShowTickets ? '1px solid #CECECE' : type === '등원' ? '2px solid #2297FF' : '1px solid #CECECE'}; */
  border: 1px solid #cecece;
  background: ${({type}) => (type === '등원' ? '#fff' : '#f9f9f9')};
  color: ${({type}) => (type === '등원' ? '#000' : '#f9f9f9')};
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({type}) => (type === '등원' ? 'pointer' : 'defulat')};
`

const ArrivalBorderBox = styled.div<BorderBoxProps>`
  width: 100%;
  height: 46px;
  padding: 12px;
  border-radius: 8px;
  /* border: ${({type, isShowTickets}) =>
    type === '등원' ? '1px solid #CECECE' : isShowTickets ? '1px solid #CECECE' : '2px solid #2297FF'}; */
  border: 1px solid #cecece;
  background: ${({type, isShowTickets}) => (type === '등원' ? '#f9f9f9' : '#fff')};
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${({type}) => (type === '하원' ? 'pointer' : 'defulat')};
`

const TimeDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 154px;
`

const DateText = styled.div`
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  line-height: 46px;
  margin-left: 3px;
  margin-top: 10px;
`

type SearchProps = {
  disabled: boolean
}

const SearchButton = styled.div<SearchProps>`
  border: ${props => (props.disabled ? 'none' : '1px solid #2297ff')};
  width: 100%;
  min-height: 48px;
  max-height: 48px;
  border-radius: 8px;
  background: ${props => (props.disabled ? '#e1e1e1' : '#2297ff')};
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 8px;
  color: ${props => (props.disabled ? 'grey' : '#fff')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s;

  &:hover {
    background: ${props => (props.disabled ? '#e1e1e1' : '#fff')};
    color: ${props => (props.disabled ? 'grey' : '#2297ff;')};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`
