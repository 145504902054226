export class DayEnum {
  static readonly MONDAY = new DayEnum('Mo', '월');
  static readonly TUESDAY = new DayEnum('Tu', '화');
  static readonly WEDNESDAY = new DayEnum('We', '수');
  static readonly THURSDAY = new DayEnum('Th', '목');
  static readonly FRIDAY = new DayEnum('Fr', '금');
  static readonly SATURDAY = new DayEnum('Sa', '토');
  static readonly SUNDAY = new DayEnum('Su', '일');

  constructor(readonly value: string, readonly exposure: string) {}

  static parse(value: string) {
    switch (value) {
      case DayEnum.MONDAY.value:
        return DayEnum.MONDAY;
      case DayEnum.TUESDAY.value:
        return DayEnum.TUESDAY;
      case DayEnum.WEDNESDAY.value:
        return DayEnum.WEDNESDAY;
      case DayEnum.THURSDAY.value:
        return DayEnum.THURSDAY;
      case DayEnum.FRIDAY.value:
        return DayEnum.FRIDAY;
      case DayEnum.SATURDAY.value:
        return DayEnum.SATURDAY;
      case DayEnum.SUNDAY.value:
        return DayEnum.SUNDAY;
      default:
        //TODO: error binding
        throw Error('error');
    }
  }
}
