import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import '../DatePicker/Datepicker.css'
import {ko} from 'date-fns/locale'

// image
import Img from 'components/atoms/Image'
import DatePickerArrow from 'assets/images/ic_down_arrow_black.svg'
import DisabledLeftArrow from 'assets/images/ic_calendar_disabled_left.svg'
import LeftArrow from 'assets/images/ic_calendar_left_arrow.svg'
import RightArrow from 'assets/images/ic_calendar_right_arrow.svg'
import {media} from 'styles/media'

type DatePickerProps = {
  date: any
  dateChange?: (date: Date) => void
  selectedDate?: string
}

const CustomInput = (props: any) => {
  return (
    <Input onClick={props.handleToggleModal} isOpen={props.isOpen}>
      {props.value}
      <DatePickerImg isOpen={props.isOpen}>
        <Img src={DatePickerArrow} width={'100%'} alt="arrow" />
      </DatePickerImg>
    </Input>
  )
}

function SingleDatePicker(props: DatePickerProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const datePickerRef = useRef(null)

  const selectDate = props.selectedDate
  const customDate = new Date(selectDate)

  const handleToggleModal = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (isOpen && datePickerRef.current) {
      // 달력이 모달에 렌더링되도록 설정
      datePickerRef.current.setOpen(true)
    }
  }, [isOpen])

  return (
    selectDate !== '' && (
      <DatePicker
        locale={ko}
        selected={customDate}
        onChange={props.dateChange}
        ref={datePickerRef}
        minDate={new Date()}
        showPopperArrow={false}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={() => setIsOpen(false)}
        customInput={<CustomInput value={customDate} isOpen={isOpen} handleToggleModal={handleToggleModal} />}
        dateFormat="yyyy-MM-dd (eee)"
        popperPlacement={window.innerWidth > 768 ? 'top-start' : 'bottom-start'}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          return (
            <DatePickerHeader>
              <ChangeMonthButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <Img
                  src={
                    moment(date).format('YYYY-MM') === moment(new Date()).format('YYYY-MM')
                      ? DisabledLeftArrow
                      : LeftArrow
                  }
                  width={'100%'}
                  alt="dereaseButton"
                />
              </ChangeMonthButton>
              <YearMonth>{moment(date).format('YYYY년 MM월')}</YearMonth>
              <ChangeMonthButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <Img src={RightArrow} width={'100%'} alt="inreaseButton" />
              </ChangeMonthButton>
            </DatePickerHeader>
          )
        }}
      />
    )
  )
}

export default SingleDatePicker

const ChangeMonthButton = styled.button`
  border: none;
  cursor: pointer;
  background: #fff;
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 8px;
`
type InputProps = {
  isOpen: boolean
}
const Input = styled.div<InputProps>`
  border: 1px solid #cecece;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;

  ${media.tablet} {
    border: ${props => (props.isOpen ? '2px solid #2297FF' : '1px solid #cecece')};
  }
`

type ImgProps = {
  isOpen: boolean
}

const DatePickerImg = styled.div<ImgProps>`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : '')};
  transition: transform 0.5s ease-in-out;
`

const DatePickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const YearMonth = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`
