import React from "react";
import styled from "styled-components";
import Img from "components/atoms/Image";
import { media } from "styles/media";

const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingImg>
        <Img
          src="https://window.edubrain.io/assets/images/popup/loading_window.gif"
          width={"100%"}
        />
      </LoadingImg>
    </LoadingContainer>
  );
};

export default Loading;

const LoadingContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f8f9;
  z-index: 99999999999;
`;

const LoadingImg = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;

  ${media.tablet} {
    width: 100px;
    height: 100px;
    margin: auto;
  }
`;
