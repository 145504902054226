import {CScheduleStation} from '../../model/ScheduleStation'
import React from 'react'
import styled from 'styled-components'

// components
import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import {media} from 'styles/media'
import {CDateTime} from 'model/Date'

// images
import DownArrow from 'assets/images/ic_down_line_arrow.svg'
import CloseButton from 'assets/images/ic_close_icon.svg'
import {CSearchSchedule} from 'model/Schedule'
import {timeFormat} from './ChangeSchedule'

type submitDataProps = {
  departureStation: string
  arrivalStation: string
}

type ModalProps = {
  stationData: CScheduleStation
  onClose?: () => void
  putChangeSchedule?: () => void
  data?: CSearchSchedule
  submitStation: submitDataProps
  selectedDate: string
  selectedStation: string
}

const SubmitModal = (props: ModalProps) => {
  const {stationData, onClose, putChangeSchedule, data, submitStation, selectedStation, selectedDate} = props
  const scheduleDate = CDateTime.create(stationData?.scheduleDate)
  const date = CDateTime.create(selectedDate)

  return (
    <Container>
      <TextWrapper>
        <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
          변경하실 노선 정보를 확인해 주세요.
        </Text>
      </TextWrapper>

      <StationWrapper>
        <Text
          fontSize={16}
          fontWeight={700}
          lineHeight={22}
          color="#708185">{`${date.getMonth()}월 ${date.getDay()}일 ${date.getDayOfTheWeek().exposure}요일`}</Text>

        <BusNameWrapper>
          <Text fontSize={16} fontWeight={500} lineHeight={22} color="#fff">
            {data.busName}
          </Text>
        </BusNameWrapper>
        <Summary>
          <TimeWrapper>
            <TimeSpan>
              <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                {Number(data.startTime.slice(0, 2)) < 12 ? '오전' : '오후'}
              </Text>
              <Text fontSize={24} fontWeight={500} lineHeight={30} letterSpacing={-0.48}>
                {timeFormat(data.startTime)}
              </Text>
            </TimeSpan>
            <ImgWrapper>
              <Img src={DownArrow} width={'20px'} height={'20px'} alt="arrow" />
            </ImgWrapper>
            <TimeSpan>
              <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                {Number(data.startTime.slice(0, 2)) < 12 ? '오전' : '오후'}
              </Text>
              <Text fontSize={24} fontWeight={500} letterSpacing={-0.48} lineHeight={30}>
                {timeFormat(data.endTime)}
              </Text>
            </TimeSpan>
          </TimeWrapper>
          <StationNameWrapper>
            <StationSpan>
              <StationText type={stationData.scheduleType}>
                {submitStation.departureStation === '' ? selectedStation : submitStation.departureStation}
              </StationText>
            </StationSpan>
            <StationSpan>
              <StationTakeOffText type={stationData.scheduleType}>
                {submitStation.arrivalStation === '' ? selectedStation : submitStation.arrivalStation}
              </StationTakeOffText>
            </StationSpan>
          </StationNameWrapper>
        </Summary>
      </StationWrapper>
      <ButtonWrapper>
        <CancelButton onClick={onClose}>다시 선택하기</CancelButton>
        <ApplyButton onClick={putChangeSchedule}>변경하기</ApplyButton>
      </ButtonWrapper>
      <CloseImg onClick={onClose}>
        <Img src={CloseButton} width={'100%'} alt="close" />
      </CloseImg>
    </Container>
  )
}

export default SubmitModal

const Container = styled.div`
  padding: 40px 16px 16px;
  position: relative;
`

const CloseImg = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StationWrapper = styled.div`
  padding: 16px;
  border: 1px solid #e9f4ff;
  border-radius: 8px;
  margin: 20px 0px;
`

const BusNameWrapper = styled.div`
  padding: 12px 16px;
  margin-top: 16px;
  border-radius: 8px 8px 0px 0px;
  background: #2297ff;
  border: 1px solid #2297ff;
`

const Summary = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 16px;
  border: 2px solid #2297ff;
  border-radius: 0px 0px 8px 8px;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  column-gap: 5px;
`

const TimeSpan = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: center;
  align-items: flex-end;
  min-width: 110px;
`

const ImgWrapper = styled.div`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;
`

export const StationSpan = styled.div`
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

type StationTextProps = {
  type?: string
}

export const StationText = styled.div<StationTextProps>`
  font-size: 16px;
  font-weight: ${props => (props.type === '등원' ? 700 : 500)};
  line-height: 22px;
  letter-spacing: -0.32px;
  color: ${props => (props.type === '등원' ? '#2297ff' : '#191919')};

  ${media.tablet} {
    line-height: 45px;
  }
`

export const StationTakeOffText = styled(StationText)<StationTextProps>`
  font-weight: ${props => (props.type === '등원' ? 500 : 700)};
  color: ${props => (props.type === '등원' ? '#191919' : '#2297ff')};
`

const StationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: calc(100% - 116px);
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const CancelButton = styled.button`
  border: 1px solid #c9cdd2;
  background: #c9cdd2;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  min-height: 44px;
  font-family: Pretendard;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
  }
`

const ApplyButton = styled.button`
  border: 1px solid #2297ff;
  background: #2297ff;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  min-height: 44px;
  cursor: pointer;
  font-family: Pretendard;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #2297ff;
  }
`
