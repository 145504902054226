import {CScheduleStation} from '../../model/ScheduleStation'
import React from 'react'
import styled from 'styled-components'

import Img from 'components/atoms/Image'
import Text from 'components/atoms/Span'
import {media} from 'styles/media'
import {CDateTime} from 'model/Date'

import DownArrow from 'assets/images/ic_down_line_arrow.svg'
import CloseButton from 'assets/images/ic_close_icon.svg'
import {StationSpan, StationTakeOffText, StationText} from 'pages/ChangeSchedule/SubmitModal'

type SelectionProps = {
  id: number
  time?: string
  name: string
}

type ModalProps = {
  stationData: CScheduleStation
  onClose?: () => void
  selectedstation?: SelectionProps
  putChangeStationName?: () => void
}

const SubmitModal = (props: ModalProps) => {
  const {stationData, onClose, selectedstation, putChangeStationName} = props
  const scheduleDate = CDateTime.create(stationData?.scheduleDate)
  const startTime = CDateTime.create(`${stationData?.scheduleDate}T${stationData?.startTime}`)
  const endTime = CDateTime.create(`${stationData?.scheduleDate}T${stationData?.endTime}`)

  const selectedTime = CDateTime.create(`${stationData?.scheduleDate}T${selectedstation?.time}`)

  return (
    <Container>
      <TextWrapper>
        <Text fontSize={20} fontWeight={500} lineHeight={28} letterSpacing={-0.4}>
          변경하실 정류장 정보를 확인해 주세요.
        </Text>
      </TextWrapper>

      <StationWrapper>
        <Text
          fontSize={16}
          fontWeight={700}
          lineHeight={22}
          color="#708185">{`${scheduleDate.getMonth()}월 ${scheduleDate.getDay()}일 ${
          scheduleDate.getDayOfTheWeek().exposure
        }요일`}</Text>

        <Summary>
          <TimeWrapper>
            <TimeSpan>
              <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                {stationData.scheduleType === '등원' ? selectedTime.getAmPm() : startTime.getAmPm()}
              </Text>
              <Text fontSize={24} fontWeight={500} lineHeight={30} letterSpacing={-0.48}>
                {stationData.scheduleType === '등원' ? selectedTime.getTime() : startTime.getTime()}
              </Text>
            </TimeSpan>
            <ImgWrapper>
              <Img src={DownArrow} width={'20px'} height={'20px'} alt="arrow" />
            </ImgWrapper>
            <TimeSpan>
              <Text fontSize={18} fontWeight={500} lineHeight={25} letterSpacing={-0.36}>
                {stationData.scheduleType === '하원' ? selectedTime.getAmPm() : endTime.getAmPm()}
              </Text>
              <Text fontSize={24} fontWeight={500} letterSpacing={-0.48} lineHeight={30}>
                {stationData.scheduleType === '하원' ? selectedTime.getTime() : endTime.getTime()}
              </Text>
            </TimeSpan>
          </TimeWrapper>
          <StationNameWrapper>
            <StationSpan>
              <StationText type={stationData.scheduleType}>
                {stationData.scheduleType === '등원' ? selectedstation.name : stationData.departureStationName}
              </StationText>
            </StationSpan>
            <StationSpan>
              <StationTakeOffText type={stationData.scheduleType}>
                {stationData.scheduleType === '하원' ? selectedstation.name : stationData.arrivalStationName}
              </StationTakeOffText>
            </StationSpan>
          </StationNameWrapper>
        </Summary>
      </StationWrapper>
      <ButtonWrapper>
        <CancelButton onClick={onClose}>다시 선택하기</CancelButton>
        <ApplyButton onClick={putChangeStationName}>변경하기</ApplyButton>
      </ButtonWrapper>
      <CloseImg onClick={onClose}>
        <Img src={CloseButton} width={'100%'} alt="close" />
      </CloseImg>
    </Container>
  )
}

export default SubmitModal

const Container = styled.div`
  padding: 40px 16px 16px;
  position: relative;
`

const CloseImg = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StationWrapper = styled.div`
  padding: 16px;
  border: 1px solid #e9f4ff;
  border-radius: 8px;
  margin: 20px 0px;
`

const Summary = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 16px;
  margin-top: 16px;
  border: 2px solid #2297ff;
  border-radius: 8px;
`

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  column-gap: 5px;
`

const TimeSpan = styled.div`
  display: flex;
  column-gap: 6px;
  justify-content: center;
  align-items: flex-end;
  min-width: 110px;
`

const ImgWrapper = styled.div`
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;
`

const StationNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: calc(100% - 116px);
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const CancelButton = styled.button`
  border: 1px solid #c9cdd2;
  background: #c9cdd2;
  border-radius: 8px;
  padding: 10px;
  min-height: 44px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  font-family: Pretendard;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
  }
`

const ApplyButton = styled.button`
  border: 1px solid #2297ff;
  background: #2297ff;
  border-radius: 8px;
  padding: 10px;
  min-height: 44px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  font-family: Pretendard;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    color: #2297ff;
  }
`
