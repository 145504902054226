import axios, {AxiosRequestConfig} from 'axios'
import {AxiosEnum} from 'enum/AxiosEnum'
import {API} from '../Env'

export async function getCourse(scheduleID: number, dispatchCode: string, academyID: string) {
  const url = `${API}/${academyID}/dispatches/detail/${scheduleID}`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {dispatchCode: dispatchCode},
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}

export async function getDispatchDetail(dispatchCode: string, academyID: string) {
  const url = `${API}/${academyID}/dispatches/detail`

  const config: AxiosRequestConfig = {
    url: url,
    method: AxiosEnum.GET.value,
    params: {dispatchCode: dispatchCode},
  }

  const axiosResponse = await axios(config)

  return axiosResponse.data.data
}
