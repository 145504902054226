import {CScheduleStation} from '../../model/ScheduleStation'
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import Text from 'components/atoms/Span'
import Img from 'components/atoms/Image'
import Close from 'assets/images/ic_close_icon.svg'
import RightArrow from 'assets/images/ic_right_gray_arrow.svg'
import CourseFirstPin from 'assets/images/ic_course_start_pin.svg'
import {media} from 'styles/media'
import Station from './Station'
import {getDispatchDetail} from 'service/Course'
import {CCourse} from 'model/Course'
import {CSearchSchedule} from 'model/Schedule'
import {timeFormat} from './ChangeSchedule'

type CourseProps = {
  stationData?: CScheduleStation
  onClose?: () => void
  selectedInfo?: CSearchSchedule
}

const CourseStation = (props: CourseProps) => {
  const {stationData, onClose, selectedInfo} = props
  const [courses, setCourses] = useState<CCourse[]>([])
  const academyID = JSON.parse(localStorage.getItem('academyID'))

  const getCourseList = useCallback(async () => {
    await getDispatchDetail(selectedInfo.dispatchCode, academyID)
      .then(res => {
        setCourses(res.courses)
      })
      .catch(error => error)
  }, [])

  useEffect(() => {
    getCourseList()
  }, [selectedInfo.dispatchCode])

  return (
    <Container>
      <HeaderTimeWrapper>
        <Header>
          <HeaderWrapper>
            <Text fontSize={16} lineHeight={20} letterSpacing={-0.32} fontWeight={500}>
              {selectedInfo.busName}
            </Text>
          </HeaderWrapper>
          <CloseImg onClick={() => onClose()}>
            <Img src={Close} width={'100%'} alt="close" />
          </CloseImg>
        </Header>
        <TimeContainer>
          <Text fontSize={12} fontWeight={400} lineHeight={20} color="#708185">
            운행시간
          </Text>
          <TextWrapper>
            <Text fontSize={16} fontWeight={500} lineHeight={20}>
              {Number(selectedInfo.startTime.slice(0, 2)) < 12 ? '오전' : '오후'}
            </Text>
            <Text fontSize={18} fontWeight={500} lineHeight={20}>
              {timeFormat(selectedInfo.startTime)}
            </Text>
          </TextWrapper>
          <ImgWrapper>
            <Img src={RightArrow} width={'100%'} alt="right" />
          </ImgWrapper>
          <TextWrapper>
            <Text fontSize={16} fontWeight={500} lineHeight={20}>
              {Number(selectedInfo.endTime.slice(0, 2)) < 12 ? '오전' : '오후'}
            </Text>
            <Text fontSize={18} fontWeight={500} lineHeight={20}>
              {timeFormat(selectedInfo.endTime)}
            </Text>
          </TextWrapper>
        </TimeContainer>
      </HeaderTimeWrapper>
      <CourseContainer>
        <TitleWrapper>
          <Text color="#708185" fontSize={12}>
            예정 시간
          </Text>
          <Text color="#708185" fontSize={12}>
            정류장
          </Text>
        </TitleWrapper>

        <Wrapper>
          {courses?.length > 0 && (
            <PinImgWrapper>
              <Img src={CourseFirstPin} width={'6px'} alt="firstpin" />
            </PinImgWrapper>
          )}

          {courses?.length > 0 &&
            courses?.map((detail: any, idx: number) => {
              return (
                <Station
                  type={stationData.scheduleType}
                  key={`detail-${idx}`}
                  order={idx}
                  detail={detail}
                  // busDetail={courses[idx]}
                  isLast={courses.length - 1 === idx}
                  detailsCnt={courses.length}
                />
              )
            })}
        </Wrapper>

        {/* <ConfirmButton onClick={() => onClose()}>확인</ConfirmButton> */}
      </CourseContainer>
    </Container>
  )
}

export default CourseStation

const Container = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    display: none; // chrome, safari, opera
  }
  scrollbar-width: none; // firefox

  -ms-overflow-style: none; // IE, edge
`

const HeaderTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 110px;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 999999;

  ${media.tablet} {
    position: sticky;
    top: 0;
  }
`

const Header = styled.div`
  display: flex;
  padding: 18px 16px;
  border-bottom: 1px solid #ebebed;
  justify-content: center;

  ${media.tablet} {
    justify-content: space-between;
  }
`

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet} {
    justify-content: flex-start;
  }
`

const CloseImg = styled.div`
  position: absolute;
  top: 19px;
  right: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const TimeContainer = styled.div`
  padding: 16px;
  background: #f7f7f7;
  display: flex;
  column-gap: 10px;
  align-items: center;
`

const ImgWrapper = styled.div`
  width: 12px;
  height: 12px;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`

const CourseContainer = styled.div`
  background: #fff;
  padding: 124px 0px 0px;
  height: calc(100% - 110px);

  ${media.tablet} {
    padding: 14px 0px 0px;
    border-radius: 8px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  column-gap: 61px;
  padding: 0 16px;
`

const Wrapper = styled.ul`
  width: 100%;
  position: relative;
  background: #fff;
`

const PinImgWrapper = styled.div`
  z-index: 11;
  position: absolute;
  top: 57px;
  left: 87px;
`

const ConfirmButton = styled.button`
  width: calc(100% - 32px);
  min-height: 48px;
  background: #2297ff;
  color: #fff;
  border: 1px solid #e9f4ff;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-left: 16px;
  font-size: 16px;
  font-family: Pretendard;
  font-weight: 700;
  line-height: 28px;
  transition: all 0.2s;

  &:hover {
    background: #fff;
    border: 1px solid #2297ff;
    color: #2297ff;
    cursor: pointer;
  }
`
