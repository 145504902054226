import React from "react";
import styled, { css } from "styled-components";

// componenets
import { media } from "styles/media";
import Text from "components/atoms/Span";
import Img from "components/atoms/Image";

// image
import DepartureStationIcon from "assets/images/ic_departure_station_icon.svg";
import ArrivalStationIcon from "assets/images/ic_arrival_station_icon.svg";
import BusStopArrow from "assets/images/ic_course_arrow_icon.svg";
import BusIconBlue from "assets/images/ic_bus_icon_blue.svg";
import Dot from "assets/images/ic_gray_dot.svg";
import BlueDot from "assets/images/ic_blue_dot.svg";
import BlueDotTop from "assets/images/ic_blue_dot_top.svg";
import BlueDotBottom from "assets/images/ic_blue_dot_bottom.svg";

type StationProps = {
  id: number;
  lat: number;
  lng: number;
  name: string;
};

type BusDetailProps = {
  arriveTargetTime: string;
  arriveTime: string;
  departureTime: string;
  position: string;
  station: StationProps;
};

type SelectionProps = {
  id: number;
  name: string;
};

type StationCourseProps = {
  order?: number;
  detail?: any;
  busDetail?: BusDetailProps;
  isLast: boolean;
  detailsCnt?: number;
  type?: string;
  selectedstation?: SelectionProps;
  changeselectedstation?: any;
  exceptstation?: string;
};

const Station: React.FC<StationCourseProps> = (props) => {
  const {
    order,
    detail,
    busDetail,
    isLast,
    type,
    selectedstation,
    changeselectedstation,
    exceptstation,
  } = props;
  const stationId = Number(detail.station.id);
  const stationname = detail.station.name;

  const coursecnt = props.detailsCnt - 1;

  return (
    <Stationli
      key={detail.id}
      order={order}
      stationid={stationId}
      pickupstationid={detail?.station?.students[0]?.pickUpStationId}
    >
      <TimeBox arriveTime={detail.arriveTime}>
        <TimeGroup>
          <EstimatedArrivalTime>{detail.station.time}</EstimatedArrivalTime>
        </TimeGroup>
      </TimeBox>
      {/* {busDetail.position === 'EXISTS' && (
        <BusIconWrapper
          isoperation={
            isLast ? 'isLast' : busDetail.departureTime ? 'true' : 'false'
          }
        >
          <Img src={BusIconBlue} width={'100%'} height={'100%'} alt="bus" />
        </BusIconWrapper>
      )} */}
      <StationBox
        order={order}
        coursecnt={Number(coursecnt)}
        isstudent={detail.station.students?.length}
        selectedstation={selectedstation.name}
        stationname={stationname}
      >
        <StationName>
          <StationBorder
            onClick={() =>
              selectedstation === stationname || exceptstation === stationname
                ? {}
                : changeselectedstation(
                    stationId,
                    detail.station?.time,
                    detail.station?.name
                  )
            }
            selectedstation={selectedstation.name}
            stationname={detail?.station?.name}
            exceptstation={exceptstation}
          >
            <Text
              fontSize={16}
              fontWeight={500}
              lineHeight={24}
              color={"#191919"}
            >
              {detail.station?.name}
            </Text>
          </StationBorder>
        </StationName>
        {type === "등원" && stationname === selectedstation && (
          <StudentWrapper>
            <Text
              fontSize={12}
              lineHeight={24}
              color="#585858"
              letterSpacing={-0.24}
            >
              승차 예정
            </Text>
          </StudentWrapper>
        )}
        {type === "하원" && stationname === selectedstation && (
          <StudentWrapper>
            <Text
              fontSize={12}
              lineHeight={24}
              color="#585858"
              letterSpacing={-0.24}
            >
              하차 예정
            </Text>
          </StudentWrapper>
        )}
      </StationBox>
      {order === Number(coursecnt) && (
        <ImgWrapper
          stationname={stationname}
          selectedstation={selectedstation.name}
        >
          <Img
            src={stationname === selectedstation.name ? BlueDotBottom : Dot}
            width={stationname === selectedstation.name ? "24px" : "6px"}
            alt="dot"
          />
        </ImgWrapper>
      )}
      {order === 0 && stationname === selectedstation.name && (
        <FirstPin>
          <Img src={BlueDotTop} />
        </FirstPin>
      )}
    </Stationli>
  );
};

export default Station;

const FirstPin = styled.div`
  position: absolute;
  top: 50px;
  z-index: 100;
  left: 78px;
  width: 24px;
  height: 24px;
`;
type ImgProps = {
  stationname?: string;
  selectedstation?: string;
};

const ImgWrapper = styled.div<ImgProps>`
  position: absolute;
  top: ${({ stationname, selectedstation }) =>
    stationname === selectedstation ? "-17px" : "-5px"};
  left: ${({ stationname, selectedstation }) =>
    stationname === selectedstation ? "78px" : "87px"};
`;
interface StationBoxProps {
  position?: number | undefined;
  departureTime?: string | null | undefined;
  order?: number;
  coursecnt?: number;
  isstudent?: number;
  selectedstation?: string;
  stationname?: string;
}

type BusIconProps = {
  isoperation: string;
};

const BusIconWrapper = styled.div<BusIconProps>`
  position: absolute;
  top: ${({ isoperation }) =>
    isoperation === "true"
      ? "70px"
      : isoperation === "isLast"
      ? "12px"
      : "47px"};
  width: 32px;
  height: 32px;
  left: 74px;
  z-index: 99;
`;

const StationBox = styled.div<StationBoxProps>`
  flex-basis: 80%;
  ${({ order, coursecnt }) =>
    order === coursecnt
      ? css`
          border-left: none;
        `
      : order === 0
      ? css`
          border-left: none;
        `
      : order !== coursecnt
      ? css`
          border-left: 2px solid #999999;
          position: absolute;
          top: 0px;
        `
      : ""};
  padding: ${({ isstudent }) =>
    isstudent === 1 ? "0 0 16px 30px" : "0 0 35px 30px"};
  position: relative;
  ::before {
    ${({ order, coursecnt, selectedstation, stationname }) =>
      order === 0
        ? css`
            content: url(${DepartureStationIcon});
            margin-left: 2px;
            margin-top: 10px;
            width: 50px;
          `
        : order === coursecnt
        ? css`
            content: url(${ArrivalStationIcon});
            margin-left: 2.5px;
            margin-top: 10px;
          `
        : css`
            content: url(${stationname === selectedstation
              ? BlueDot
              : BusStopArrow});
            margin-top: 10px;
          `}
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 30px;
    width: 24px;
    height: 20px;
    position: absolute;
    left: -13px;
    top: 8px;
    line-height: 1.1;
    background-color: #fff;
  }

  @-webkit-keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(-20px);
      opacity: 0;
    }
    50% {
      transform: translateY(0px);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }

  ::after {
    content: "";
    position: absolute;
    display: ${({ position }) => (position === 1 ? "block" : "none")};
    width: 30px;
    height: 30px;
    background: url(${BusIconBlue}) center center no-repeat;
    left: -16px;
    z-index: 1;
    top: -8px;
  }
  ${media.desktop} {
    flex-basis: 70%;
  }

  #empty {
    margin-left: 50px;
  }
`;

interface StationLiProps {
  status?: number | undefined;
  order?: number;
  stationid?: number;
  pickupstationid?: number;
}

const Stationli = styled.li<StationLiProps>`
  display: flex;
  position: relative;
`;

interface TimeBoxProps {
  arriveTime?: string | null | undefined;
}

const TimeBox = styled.div<TimeBoxProps>`
  flex-basis: 66px;
  text-align: right;
  margin-right: 16px;
  min-width: 73px;
  padding: 16px;
  ${({ arriveTime }) => arriveTime !== "" && css``}
`;

const TimeGroup = styled.div`
  display: inline;
  width: 66px;
  text-align: left;
  min-height: 115px;
`;

const EstimatedArrivalTime = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 36px;
`;

export const StationName = styled.div`
  padding-top: 10px;
`;

const StudentWrapper = styled.div`
  display: inline-block;
  margin-top: 4px;
  align-items: center;
`;

type StationNameProps = {
  selectedstation: string;
  stationname: string;
  exceptstation?: string;
};

const StationBorder = styled.div<StationNameProps>`
  display: inline-block;
  border-radius: 8px;
  padding: ${({ selectedstation, stationname }) =>
    selectedstation === stationname ? "6px 0px" : "6px 16px"};
  border: ${({ selectedstation, stationname, exceptstation }) =>
    selectedstation === stationname || exceptstation === stationname
      ? ""
      : "1px solid #d8d8d8"};
  cursor: ${({ selectedstation, stationname, exceptstation }) =>
    selectedstation === stationname || exceptstation === stationname
      ? "default"
      : "pointer"};
`;
