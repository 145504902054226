import React, { CSSProperties, useMemo } from 'react';

type TextProps = {
  fontSize?: number;
  fontWeight?: number;
  color?: string;
  children?: string;
  lineHeight?: number;
  className?: string;
  letterSpacing?: number;
  style?: CSSProperties;
};

const Text = (props: TextProps) => {
  const fontSize = useMemo((): string => {
    if (props.fontSize === null || props.fontSize === undefined) {
      return '14px';
    }
    return `${props.fontSize}px`;
  }, [props.fontSize]);

  const fontWeight = useMemo((): number => {
    if (props.fontWeight === null || props.fontWeight === undefined) {
      return 400;
    }
    return props.fontWeight;
  }, [props.fontWeight]);

  const lineHeight = useMemo((): string => {
    if (props.lineHeight === null || props.lineHeight === undefined) {
      return '20px';
    }
    return `${props.lineHeight}px`;
  }, [props.lineHeight]);

  const letterSpacing = useMemo((): string => {
    if (props.letterSpacing === null || props.letterSpacing === undefined) {
      return `-0.5px`;
    }
    return `${props.letterSpacing}px`;
  }, [props.letterSpacing]);

  const color = useMemo((): string => {
    if (props.color === null || props.color === undefined) {
      return '#191919';
    }
    return props.color;
  }, [props.color]);

  return (
    <span
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color,
        lineHeight: lineHeight,
        letterSpacing: letterSpacing,
        display: 'inline-block',
      }}
    >
      {props.children}
    </span>
  );
};

export default Text;
