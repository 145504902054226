import React from 'react'
import styled from 'styled-components'
import {media} from 'styles/media'

type PopUpProps = {
  id?: boolean
  onClose?: () => void
  contents?: any
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
  top?: string
  left?: string
}

const HalfPopUp: React.FC<PopUpProps> = props => {
  return (
    <Container open={props.id}>
      <BackgroundLayer onClick={() => props.onClose()} />
      <Contents
        width={props.width}
        height={props.height}
        mobileWidth={props.mobileWidth}
        mobileHeight={props.mobileHeight}
        top={props.top}
        left={props.left}>
        {props.contents}
      </Contents>
    </Container>
  )
}

export default HalfPopUp

type ContainerProps = {
  open?: boolean
}
const Container = styled.div<ContainerProps>`
  display: ${({open}) => (open === true ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 0px;
`

const BackgroundLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

type ContentsProps = {
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
  top?: string
  left?: string
}

const Contents = styled.div<ContentsProps>`
  position: absolute;
  top: 25%;
  left: 16px;
  border-radius: 8px;
  width: ${({mobileWidth}) => (mobileWidth ? mobileWidth : 'auto')};
  height: ${({mobileHeight}) => (mobileHeight ? mobileHeight : 'auto')};
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  ${media.tablet} {
    width: ${({width}) => (width ? width : 'auto')};
    height: ${({height}) => (height ? height : 'auto')};
    top: ${({top}) => (top ? top : '10%')};
    left: ${({left}) => (left ? left : '20%')};
    border-radius: 8px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
`
