import React from 'react'
import styled from 'styled-components'
import {media} from 'styles/media'

type PopUpProps = {
  id?: boolean
  onClose?: () => void
  contents?: any
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
}

const FullPopUp: React.FC<PopUpProps> = props => {
  return (
    <Container open={props.id}>
      <BackgroundLayer onClick={() => props.onClose()} />
      <Contents
        width={props.width}
        height={props.height}
        mobileWidth={props.mobileWidth}
        mobileHeight={props.mobileHeight}>
        {props.contents}
      </Contents>
    </Container>
  )
}

export default FullPopUp

type ContainerProps = {
  open?: boolean
}
const Container = styled.div<ContainerProps>`
  display: ${({open}) => (open === true ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 0px;
`

const BackgroundLayer = styled.div`
  /* ${media.tablet} { */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.7); */
  /* } */
`

type ContentsProps = {
  width?: string
  height?: string
  mobileWidth?: string
  mobileHeight?: string
}

const Contents = styled.div<ContentsProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${({mobileWidth}) => (mobileWidth ? mobileWidth : '100vw')};
  height: ${({mobileHeight}) => (mobileHeight ? mobileHeight : '100vh')};
  background: #fff;
  overflow: scroll;

  ${media.tablet} {
    width: ${({width}) => (width ? width : '472px')};
    height: ${({height}) => (height ? height : '633px')};
    overflow: scroll;
    top: 14%;
    left: 110px;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  }
`
